import React, { useEffect, useRef, useState } from "react";
import { useIsInViewport, useWindowSize } from "../../../hooks";
import Accordion from "../../common/accordion/Accordion";
import "./FAQ.scss";

const FAQ = ({ title, content }) => {
  const [FAQs, setFAQs] = useState([]);

  const windowSize = useWindowSize();
  const isInViewportThreshold = windowSize.width > 768 ? 0.5 : 0.2;
  const sectionRef = useRef(null);
  const isInViewport = useIsInViewport(sectionRef, isInViewportThreshold);
  const [showAnimation, setShowAnimation] = useState(false);


  useEffect(() => {
    if (isInViewport) {
      setTimeout(() => setShowAnimation(isInViewport), 50);
    }
  }, [isInViewport]);

  useEffect(() => {
    if (content) {
      const updatedContent = content?.map((el, index) => {
        return {
          id: index,
          expanded: false,
          title: el.question,
          body: el.answer,
        };
      });

      setFAQs(updatedContent);
    }
  }, [content]);

  return (
    <>
      <section
        className={`section section--faq${showAnimation ? " onScreen" : ""}`}
        ref={sectionRef}
      >
        <div className="section__innerWrapper">
          <article className="overDeco">
            <h2 className="center animateIn">
              <a className="anchor" name="faq">
                FAQ
              </a>
            </h2>
            <h3 className="center animateIn">{title}</h3>
            <Accordion className="animateIn" content={FAQs} />
          </article>
        </div>
      </section>
    </>
  );
};

export default FAQ;
