import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { getMe } from "../../../api/me";
import { login } from "../../../api/security";
import {
  Box,
  Button,
  Footer,
  Form,
  InputField,
} from "../../../components/common";
import { PhoneInputField } from "../../../components/common/input/PhoneInput";
import { saveLoginToken } from "../../../helpers/authorizationToken";
import AuthorisationDecorations from "../authorization-decorations/AuthorisationDecorations";
import { Stack } from "@mui/material";
import "./Login.scss";

const validationSchema = object({
  phone: string().required("Phone is a required field"),
  password: string().trim().required("Password is a required field"),
});

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState();

  const handleSubmit = async (data) => {
    let formattedData = {
      ...data,
      phone: data.phone.replace("(", "").replace(")", "").replace(/\s/g, ""),
    };
    setIsLoading(true);
    try {
      await login(formattedData).then(async (res) => {
        if (res?.data?.token) {
          saveLoginToken(res.data.token);

          localStorage.removeItem('old_dashboard');

          await getMe().then((res) => {

            if (!res?.data?.fundingApplications?.length) {
              navigate("/funding-application");
            } else {
              const latestApplicationState =
                res.data.fundingApplications[0].currentState;

              switch (latestApplicationState) {
                case "softDeclined":
                case "preApproved":
                case "stepTwoFinished":
                  navigate("/funding-application");
                  break;
                default:
                  navigate("/dashboard");
                  break;
              }
            }
          });
        }
      });
    } catch (e) {
      console.log("Error: ", e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  return (
    <>
      <main className="login">
        <div className="flex-column content">
          <h1>Welcome back!</h1>
          <Box className="flex-column justify-center align-center">
            <Form onSubmit={handleSubmit} validationSchema={validationSchema}>
              <PhoneInputField
                id="phone"
                name="phone"
                labelAbove={"Phone Number"}
              />
              <InputField
                id="password"
                name="password"
                type="password"
                labelAbove={"Password"}
              />
              <Stack direction="column" gap={1}>
                <Button
                  className="primary full-width xl"
                  type="submit"
                  label="Log in"
                />
                <p className="link" onClick={() => navigate("/reset-password")}>
                  Forgot password?
                </p>
              </Stack>
              {/* TODO: remove when done with testing */}
              {/* <Button
                className="primary full-width xl"
                label="Log in (with test data)"
                onClick={() => handleSubmit(testData)}
              /> */}
            </Form>
          </Box>
        </div>
        <AuthorisationDecorations />
      </main>
      <Footer />
    </>
  );
};

export default Login;
