import React from "react";
import { useFormContext } from "react-hook-form";
import { getFormErrorMsg } from "../../../helpers/formErrorMessages";
import { useFormSubmitStatus } from "../../../hooks";
import "./DatePicker.scss";

export const DatePickerField = ({
  id,
  name,
  disabled,
  label,
  labelAbove,
  autocomplete,
  type = "date",
  containerClassName,
  required,
  ...props
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const submitting = useFormSubmitStatus();

  return (
    <div
      className={`input-container full-width ${containerClassName} datepicker ${
        Boolean(getFormErrorMsg(errors, name)) && "error"
      }${required ? " required" : ""}`}
    >
      {labelAbove && (
        <label htmlFor={id} className="label-above">
          {labelAbove}
        </label>
      )}
      <input
        id={id}
        type={type}
        {...register(name)}
        disabled={disabled || submitting}
        {...props}
      />
      {Boolean(getFormErrorMsg(errors, name)) && (
        <div className="error-hint">{getFormErrorMsg(errors, name)}</div>
      )}
    </div>
  );
};

export const DatePicker = ({
  id,
  label,
  value,
  onChange,
  labelAbove,
  autocomplete,
  type = "date",
  containerClassName,
  ...props
}) => {
  return (
    <div
      className={`input-container full-width ${containerClassName} datepicker`}
    >
      {labelAbove && (
        <label htmlFor={id} className="label-above">
          {labelAbove}
        </label>
      )}
      <input
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        list=""
        {...props}
      />
    </div>
  );
};
