import api from "./api";

export const getBlog = async (data) => {
  let url = "/blogs?";
  if (data.page) {
    url += `page=${data.page}`;
  }
  if (data.categoryId) {
    url += `&category.id=${data.categoryId}`;
  }
  return api({
    url,
    data,
  });
};
