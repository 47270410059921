import { CardActions } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { format, parseISO } from "date-fns";
import * as React from "react";
import CustomChip from "../chip/Chip";
import "./Card.scss";

export default function CustomCard({ data }) {
  return (
    <Card className="card">
      <CustomChip data={data} />
      <a href={`/blog/${data.fullSlug}`} title="Read more" className="card__image">
        <img
          src={`${process.env.REACT_APP_IMG_URL}/blog/${data.headerFilename}`}
          alt={data.headerFilenameAlt !== '' ? data.headerFilenameAlt : data.title}
        />
      </a>
      <div className="card__date-time">
        <div className="card__date">
          {format(parseISO(data.publishedAt), "MMMM dd, yyyy")}
        </div>
        <div className="card__time">{data.readingTime} min</div>
      </div>
      <CardContent className="card__content">
        <h2><a href={`/blog/${data.fullSlug}`} title={data.title}>{data.title}</a></h2>
        <p>{data.summary}</p>
      </CardContent>
      <CardActions className="card__actions">
        <a href={`/blog/${data.fullSlug}`} title="Read more">
          Read more
        </a>
      </CardActions>
    </Card>
  );
}
