import { useMemo } from "react";
import { useQuery } from "react-query";
import { getMe } from "../../api/me";
import { isLogged } from "../../helpers/authorizationToken";

export default function useGetMe(options) {
  const {
    data: result,
    refetch,
    isFetching,
    isRefetching,
  } = useQuery("get-me", () => getMe(), {
    enabled: isLogged(),
    staleTime: 120 * 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  });

  const formattedResult = useMemo(
    () => ({
      ...result,
      refetch,
      isFetching,
      isRefetching,
      data: result?.data,
    }),
    [result, refetch, isFetching, isRefetching]
  );

  return formattedResult;
}
