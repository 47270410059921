const getNestedErrorMsg = (errors, name) => {
  if (!name?.includes('.')) return null;

  const path = name.split('.');
  let result = errors;
  for (let i = 0; i < path.length; i++) {
    result = result?.[path[i]];
  }

  return result?.message;
};

export const getFormErrorMsg = (errors, name) => {
  return (
    getNestedErrorMsg(errors, name) ||
    errors[name]?.message ||
    (Object.values(errors[name] || {})?.[0])?.message ||
    ''
  );
};
