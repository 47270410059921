import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { boolean, object, string } from "yup";
import { createUser } from "../../../api/user";
import {
  Button,
  DocumentsCheckboxField,
  DropdownField,
  Form,
  InputField,
} from "../../../components/common";
import { PhoneInputField } from "../../../components/common/input/PhoneInput";
import { saveLoginToken } from "../../../helpers/authorizationToken";
import "./Register.scss";
import {useGetMe} from "../../../hooks";
import {FIRST_STEP} from "../../../helpers/steps";

const testData = {
  firstname: "Thomas",
  lastname: "Thomas",
  email: "user@gmail.com",
  phone: "455455545555",
  plainPassword: "some_pass_word",
  // state: "Random",
};

export const validationSchema = object({
  firstname: string().required("First name is a required field"),
  lastname: string().required("Last name is a required field"),
  email: string()
    .email("Please enter a valid email")
    .trim()
    .required("E-mail is a required field"),
  phone: string()
    .required("Phone is required and should be a US number")
    .min(17, "Must be a US number")
    .max(17, "Must be a US number"),
  plainPassword: string().required("Password is a required field"),
  how: string().required("Please tell us how you heard about us"),
  agreement: boolean().isTrue(
    "You must agree to the Privacy Policy & Terms of Service"
  ),
});

const Profile = ({isReadonly = false, setPreviewState}) => {
  const navigate = useNavigate();
  const { state: prefilledData } = useLocation();
  const [submitting, setSubmitting] = useState(false);
  const [defaultValues, setDefaultValues] = useState(prefilledData);
  const { data: me, isLoading, isRefetching } = useGetMe();


  console.log(me);

  useEffect(() => {
    if(isReadonly) {
      setDefaultValues({
        firstname: me?.firstname,
        lastname: me?.lastname,
        email: me?.email,
        phone: me?.phone,
        how: me?.how,
        agreement: true,
      })
    }
  }, [me]);

  const handleSubmit = (data) => {
    if (isReadonly) {
      navigate("/funding-application#firstStep");
      setPreviewState(FIRST_STEP)
      return;
    }

    delete data.agreement;

    let formattedData = {
      ...data,
      phone: data.phone.replace("(", "").replace(")", "").replace(/\s/g, ""),
    };
    setSubmitting(true);

    (async () => {
      try {
        await createUser(formattedData).then((res) => {
          if (res?.data?.token) {
            saveLoginToken(res.data.token);
            navigate("/verification");
          }
        });
      } catch (e) {
        console.log("Error: ", e);
      }
    })();

    setSubmitting(false);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    document.body.classList.add("register");

    return () => {
      document.body.classList.remove("register");
    };
  }, []);

  return (
    <div className="step-wrapper profile flex-column align-center">
      <h1 style={{ fontSize: "24px", fontWeight: 400, paddingTop: '2rem' }}>Profile Info</h1>
      <Form
        onSubmit={handleSubmit}
        validationSchema={isReadonly ? object({}) : validationSchema}
        defaultValues={defaultValues}
      >
        <InputField
          id="firstname"
          name="firstname"
          placeholder="Input first name"
          labelAbove="First name"
          required={!isReadonly}
          readOnly={isReadonly}
        />
        <InputField
          id="lastname"
          name="lastname"
          placeholder="Input last name"
          labelAbove="Last name"
          required={!isReadonly}
          readOnly={isReadonly}
        />
        <PhoneInputField
          id="phoneNumber"
          name="phone"
          placeholder="Phone number"
          labelAbove="Phone number"
          required={!isReadonly}
          // disabled={isReadonly}
        />
        <InputField
          id="email"
          name="email"
          placeholder="example@mail.com"
          labelAbove="E-mail"
          required={!isReadonly}
          readOnly={isReadonly}
        />
        <InputField
          id="password"
          name="plainPassword"
          placeholder="Create password"
          type="password"
          labelAbove="Password"
          required={!isReadonly}
          readOnly={isReadonly}
        />
        <DropdownField
          id="how"
          name="how"
          placeholder="Tell us how you heard about us"
          labelAbove="How did you hear about us?"
          options={[
            { label: "Instagram" },
            { label: "TikTok" },
            {
              label: "LinkedIn",
            },
            { label: "X" },
            { label: "Email" },
            { label: "Blog" },
            { label: "Search Engine" },
            { label: "From a Friend" },
            { label: "Other" },
          ]}
          dropdownLabelField="label"
          dropdownValueField="label"
          defaultValue={defaultValues?.how}
          required
          readOnly={isReadonly}
        />
        <DocumentsCheckboxField
          id="agreement"
          name="agreement"
          customErrorMsg="You must agree to the Privacy Policy & Terms of Service"
          label={
            <div>
              By checking this box, I expressly consent to YELO Funding
              contacting me using the information I provide.
            </div>
          }
        />
        <Button
          className="primary full-width xl"
          type="submit"
          label="Next step"
          disabled={submitting}
        />
        {/* <Button
          className="secondary full-width xl"
          label="Prefill form"
          disabled={submitting}
          onClick={(e) => {
            e.preventDefault();
            setDefaultValues({
              firstname: "John",
              lastname: "Doe",
              email: `testuser${
                Math.floor(Math.random() * 90000) + 10000
              }@email.com`,
              phone: `+1 (${Math.floor(Math.random() * 900) + 100}) ${
                Math.floor(Math.random() * 900) + 100
              } ${Math.floor(Math.random() * 9000) + 1000}`,
              plainPassword: "test",
              how: "Instagram",
              agreement: true,
            });
          }}
        /> */}
      </Form>
    </div>
  );
};

export default Profile;
