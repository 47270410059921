import React, { useEffect, useState, useMemo } from "react";

const useIntersectionRatio = (ref) => {

  const [intersectionRatio, setIntersectionRatio] = useState(0);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        setIntersectionRatio(entry.intersectionRatio);
      }, {
        root: null, 
        rootMargin: '0px 0px 0px 0px',
        threshold: new Array(101).fill(0).map((v, i) => i * 0.01)
      }
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return intersectionRatio;
};

export default useIntersectionRatio;