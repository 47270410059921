import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { object, string } from "yup";
import {
  changeForgottenPassword,
  verifyForgottenPassword,
} from "../../../../api/security";
import {
  Box,
  Button,
  Footer,
  Form,
  InputField,
} from "../../../../components/common";
import AuthorisationDecorations from "../../authorization-decorations/AuthorisationDecorations";
import qs from "qs";
import { useMemo } from "react";
import { useSnackbar } from "notistack";
import "../Login.scss";

const validationSchema = object({
  password: string().required("This is a required field"),
  "password-new": string()
    .required("This is a required")
    .test("match", "Passwords do not match", function (value) {
      return value === this.parent.password;
    }),
});

const NewPassword = () => {
  const navigate = useNavigate();
  const [displayForm, setDisplayForm] = useState(false);
  const [invalidLink, setInvalidLink] = useState(false);
  const [changed, setChanged] = useState(false);
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const verificationHash = useMemo(() => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const { hash } = queryParams || {};
    return hash;
  }, [location]);

  const handleSubmit = async (data) => {
    const { password } = data;
    await changeForgottenPassword({ plainPassword: password, verificationHash })
      .then((res) => {
        enqueueSnackbar("Password reset successfully", {
          variant: "success",
        });
        setDisplayForm(false);
        setChanged(true);
        // navigate("/login");
      })
      .catch((e) => console.log("Error: ", e));
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });

    if (verificationHash) {
      (async () => {
        await verifyForgottenPassword({ verificationHash })
          .then(({ response }) => {
            setDisplayForm(true);
          })
          .catch((e) => {
            console.log("Error: ", e);
            setInvalidLink(true);
          });
      })();
    }
  }, []);

  return (
    <>
      <main className="login">
        <div className="flex-column content" style={{ minHeight: 300 }}>
          <h1>Password Reset</h1>
          <Box className="flex-column justify-center align-center">
            {displayForm && (
              <Form onSubmit={handleSubmit} validationSchema={validationSchema}>
                <InputField
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Create password"
                  labelAbove="New Passowrd"
                />
                <InputField
                  id="password-new"
                  name="password-new"
                  type="password"
                  placeholder="Confirm password"
                  labelAbove="Confirm New Password"
                />
                <Button
                  className="primary full-width xl"
                  type="submit"
                  label="Confirm Password Reset"
                />
              </Form>
            )}
            {changed && !displayForm && (
              <div
                className="flex-column"
                style={{ gap: "2rem", padding: "1.6rem" }}
              >
                <p>Password changed successfully</p>
                <Button
                  className="primary full-width xl"
                  label="Back to login"
                  onClick={() => navigate("/login")}
                />
              </div>
            )}
            {(invalidLink || !verificationHash) && (
              <div
                className="flex-column"
                style={{ padding: "1.6rem", textAlign: "center" }}
              >
                <p>Invalid Link. </p>
              </div>
            )}
          </Box>
        </div>
        <AuthorisationDecorations />
      </main>
      <Footer />
    </>
  );
};

export default NewPassword;
