import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { getFormErrorMsg } from "../../../helpers/formErrorMessages";
import { useFormSubmitStatus } from "../../../hooks";
import "./Dropdown.scss";

export const DropdownField = ({
  id,
  name,
  disabled,
  label,
  labelAbove,
  dropdownLabelField,
  dropdownValueField = "@id",
  options = [],
  containerClassName = "",
  required,
  defaultValue,
  readOnly = false,
  ...props
}) => {
  const {
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext();
  const submitting = useFormSubmitStatus();

  const emptyOption = {
    [dropdownLabelField]: "",
    [dropdownValueField]: "",
  };

  const [dropdownValue, setDropdownValue] = useState(defaultValue || "");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const handleFocus = () => {
    if(readOnly) return;

    // props.onFocus && props.onFocus();
    setShowSuggestions(true);
  };

  useEffect(() => {
    setDropdownValue(defaultValue);
  }, [defaultValue])

  const handleOnBlur = () => {
    // props.onBlur && props.onBlur();
    setShowSuggestions(false);
  };

  const handleClick = (e) => {
    if (e.target.id !== id) {
      handleOnBlur();
    }
  };

  const handleSelect = (id, label) => {
    setDropdownValue(label);
    setValue(name, id);
    clearErrors(name);
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
  }, []);

  return (
    <>
      <div
        className={`input-container full-width dropdown ${
          Boolean(getFormErrorMsg(errors, name)) && "error"
        }${required ? " required" : ""}`}
      >
        {labelAbove && (
          <label htmlFor={id} className="label-above">
            {labelAbove}
          </label>
        )}

        <input
          id={id}
          type={"text"}
          list=""
          {...props}
          disabled={disabled || submitting}
          onFocus={handleFocus}
          value={dropdownValue}
          readOnly
          // onBlur={handleOnBlur}
          className={showSuggestions ? "show-suggestions" : ""}
        />
        {showSuggestions && (
          <div className="dropdown-suggestions">
            <div className="inner-wrapper">
              {[emptyOption, ...options]?.map((option, index) => (
                <div
                  key={index}
                  className="option"
                  onClick={() => {
                    handleSelect(
                      option?.[dropdownValueField],
                      option?.[dropdownLabelField]
                    );
                    setShowSuggestions(false);
                  }}
                >
                  {option?.[dropdownLabelField]}
                </div>
              ))}
            </div>
          </div>
        )}

        {/* <select
          id={id}
          name={name}
          disabled={disabled || submitting}
          {...props}
          {...register(name)}
          list=""
          value={value}
        >
          {[emptyOption, ...options]?.map((option, index) => (
            <option
              key={index}
              value={
                dropdownValueField ? option[dropdownValueField] : option["@id"]
              }
            >
              {option[dropdownLabelField]}
            </option>
          ))}
        </select> */}

        {Boolean(getFormErrorMsg(errors, name)) && (
          <div className="error-hint">{getFormErrorMsg(errors, name)}</div>
        )}
      </div>
    </>
  );
};

export const Dropdown = ({
  id,
  label,
  labelAbove,
  dropdownLabelField,
  options = [],
  containerClassName = "",
  ...props
}) => {
  return (
    <div className={`input-container full-width ${containerClassName}`}>
      {labelAbove && (
        <label htmlFor={id} className="label-above">
          {labelAbove}
        </label>
      )}
      <select id={id} list="" {...props}>
        {options?.map((option, index) => (
          <option key={index} value={option.name}>
            {option[dropdownLabelField]}
          </option>
        ))}
      </select>
    </div>
  );
};
