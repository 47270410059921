import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import StarIcon from "@mui/icons-material/Star";
import QuoteIcon from "../../../assets/icons/quote.svg";
import { useSwipeable } from "react-swipeable";

const Testimonials = ({ title, testimonials }) => {
  const [activeTestimonial, setActiveTestimonial] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const testimonialsPerPage = isMobile ? 1 : 3;

  const handlePrev = () => {
    setActiveTestimonial((prevIndex) => {
      const newIndex =
        prevIndex - 1 < 0
          ? testimonials.length - testimonialsPerPage
          : prevIndex - 1;
      return newIndex;
    });
  };

  const handleNext = () => {
    setActiveTestimonial((prevIndex) => {
      const newIndex =
        prevIndex + 1 >= testimonials.length - testimonialsPerPage + 1
          ? 0
          : prevIndex + 1;
      return newIndex;
    });
  };

  const handleDotClick = (index) => {
    setActiveTestimonial(index);
  };

  const handleSwipes = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
  });

  const arrowButtonStyles = {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 1,
    color: "#F9DF1C",
    backgroundColor: "transparent",
  };

  return (
    <Box
      component="section"
      {...handleSwipes}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: { xs: "calc(100% - 30px)", sm: "calc(100% - 90px)" },
        maxWidth: "1260px",
        margin: "0 auto",
        textAlign: "center",
        padding: { xs: "40px 16px", md: "72px 30px" },
        position: "relative",
        "button > svg": {
          width: 47,
          height: 47,
        },
      }}
    >
      <Typography
        variant="h3"
        component="h3"
        sx={{
          fontSize: { xs: "3rem", md: "4rem" },
          color: "#203864",
          fontWeight: "600",
          textAlign: "center",
          marginBottom: "4rem",
        }}
      >
        {title}
      </Typography>

      {!isMobile && testimonials.length > testimonialsPerPage && (
        <>
          <IconButton
            onClick={handlePrev}
            sx={{ ...arrowButtonStyles, left: -20 }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>

          <IconButton
            onClick={handleNext}
            sx={{ ...arrowButtonStyles, right: -20 }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </>
      )}

      <Box
        sx={{
          display: "flex",
          padding: "20px",
          overflow: "hidden",
          maxWidth: "100%",
          justifyContent: "center",
          flexDirection: { xs: "column", md: "row" },
          gap: "30px",
        }}
      >
        {testimonials
          .slice(activeTestimonial, activeTestimonial + testimonialsPerPage)
          .map((testimonial, index) => (
            <Box
              key={index}
              sx={{
                position: "relative",
                flex: "1",
                padding: {
                  xs: "30px 20px 90px 20px",
                  md: "45px 20px 90px 20px",
                },
                boxShadow: "0px 0px 25px rgba(10, 8, 35, 0.1)",
                borderRadius: { xs: "25px", md: "30px" },
                maxWidth: { xs: "100%", md: "360px" },
              }}
            >
              <Typography
                variant={isMobile ? "h4" : "h3"}
                gutterBottom
              >
                {testimonial.name}
              </Typography>
              <Typography
                variant="p"
                fontSize={{ xs: 14, md: 16 }}
                gutterBottom
              >
                {testimonial.position}
                <br />
                {testimonial.company}
                <br />
                {testimonial.location}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 0.5,
                  mt: 2.5,
                  mb: 2,
                }}
              >
                {Array.from({ length: testimonial.rating }).map((_, i) => (
                  <StarIcon
                    key={i}
                    sx={{ width: 26, height: 26, color: "#657DF1" }}
                  />
                ))}
              </Box>
              <Box
                component="img"
                src={QuoteIcon}
                sx={{ width: 30, height: 30, mb: 1.5 }}
                alt="Quote"
              />
              <Typography
                component="div"
                fontSize={{ xs: 15, md: 17 }}
                textAlign="justify"
                gutterBottom
              >
                {testimonial.testimonial}
              </Typography>
              <Box
                component="img"
                src={QuoteIcon}
                sx={{
                  width: 30,
                  height: 30,
                  position: "absolute",
                  bottom: 50,
                  left: "50%",
                  mt: 1.5,
                  transform: "rotate(180deg) translateX(50%)",
                }}
                alt="Quote"
              />
            </Box>
          ))}
      </Box>

      {testimonials.length > testimonialsPerPage && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
          }}
        >
          {Array(Math.ceil(testimonials.length / testimonialsPerPage))
            .fill(0)
            .map((_, index) => (
              <Box
                key={index}
                onClick={() => handleDotClick(index)}
                sx={{
                  height: "18px",
                  width: "18px",
                  backgroundColor:
                    index ===
                    Math.floor(activeTestimonial / testimonialsPerPage)
                      ? "#203864"
                      : "#A6B1C5",
                  borderRadius: "50%",
                  margin: "0 0.5rem",
                  cursor: "pointer",
                }}
              />
            ))}
        </Box>
      )}
    </Box>
  );
};

export default Testimonials;
