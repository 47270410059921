import { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useQuery } from "react-query";
import { getBlog } from "../../api/blog";

export default function useGetBlog(options) {
  const { categoryId, page } = options;
  const [allData, setAllData] = useState([]);
  const queryClient = useQueryClient();

  const result = useQuery(
    ["get-blog", { categoryId, page }],
    () => getBlog({ categoryId, page }),
    {
      keepPreviousData: true,
      staleTime: 120 * 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options,
    }
  );

  useEffect(() => {
    if (result.isSuccess) {
      setAllData((prevData) => prevData.concat(result.data.data));
    }
  }, [result.isSuccess, result.data]);

  useEffect(() => {
    setAllData([]); // Clear the data when categoryId changes
    result.refetch(); // Refetch the data with new categoryId
  }, [categoryId]);

  useEffect(() => {
    if (categoryId !== undefined) {
      result.refetch();
    }
  }, [categoryId, page]);

  useEffect(() => {
    return () => {
      queryClient.removeQueries("get-blog"); // Clean up queries when the component unmounts
    };
  }, [queryClient]);

  const formattedResult = useMemo(() => {
    return {
      ...result,
      data: allData,
    };
  }, [result, allData]);

  return formattedResult;
}
