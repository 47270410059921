import React, { useEffect, useState } from "react";
import "./Accordion.scss";

const Accordion = ({ content, className }) => {
  const [items, setItems] = useState(content);

  useEffect(() => {
    if (content) {
      setItems(content);
    }
  }, [content]);

  const handleToggle = (id) => {
    const updatedItems = items.map((item) => {
      if (item.id === id) {
        item.expanded = !item.expanded;
      }

      return item;
    });

    setItems(updatedItems);
  };

  return (
    <ul className={`accordion ${className ? ` ${className}` : ""}`}>
      {items.map((item) => {
        return (
          <li
            key={item.id}
            className={`item ${item.expanded ? "expanded" : "collapsed"}`}
            onClick={() => handleToggle(item.id)}
          >
            <article>
              <h4 className="title">
                <div
                  aria-expanded="false"
                  aria-controls="sect-1"
                  aria-disabled="false"
                >
                  {item.title}
                </div>
              </h4>
              <p
                className="body"
                dangerouslySetInnerHTML={{ __html: item.body }}
              ></p>
            </article>
          </li>
        );
      })}
    </ul>
  );
};

export default Accordion;
