import { useGetMe } from "../index";

function useCurrentFundingApplication() {
  const { data: me, refetch } = useGetMe();
  const { fundingApplications } = me || {};
  const currentApplication = fundingApplications?.[0];
  const fundingApplicationId = currentApplication?.id;

  return [ currentApplication, fundingApplicationId, refetch ];
}

export default useCurrentFundingApplication;