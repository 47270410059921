import React, { useState, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { getFormErrorMsg } from "../../../helpers/formErrorMessages";
import { useFormSubmitStatus } from "../../../hooks";
import "./Input.scss";

export const InputField = ({
  disabled,
  type = "text",
  name,
  defaultValue,
  id,
  label,
  labelAbove,
  containerClassName = '',
  currency = false,
  showErrors = true,
  errorClassName = 'error',
  ...rest
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const submitting = useFormSubmitStatus();

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    // TODO: Keep focus on input field when show/hide password
    setShowPassword((state) => !state);
  };

  const hasError = Boolean(getFormErrorMsg(errors, name));

  return (
    <div>
      <div
        className={`input-container full-width ${containerClassName} ${
          Boolean(getFormErrorMsg(errors, name)) && errorClassName
        } ${
          type === "checkbox"
            ? "checkbox"
            : type === "radio"
            ? "radio"
            : type === "password"
            ? "password"
            : ""
        }${rest.required ? " required" : ""}${disabled ? " disabled" : ""} ${
          currency ? "currency" : ""
        }`}
      >
        {labelAbove && (
          <label htmlFor={id} className="label-above">
            {labelAbove}
          </label>
        )}
        {currency && <span className="start-adornment">$</span>}
        <input
          disabled={disabled || submitting}
          {...rest}
          {...register(name)}
          id={id}
          type={type !== "password" ? type : showPassword ? "text" : "password"}
          list=""
          onWheel={(event) => event.target.blur()}
        />
        {label && <label htmlFor={id}>{label}</label>}
        {type === "password" && (
          <span className="show-password-toggle" onClick={handleShowPassword}>
            show password
          </span>
        )}
      </div>
      {hasError && showErrors && (
        <div className="error-hint">{getFormErrorMsg(errors, name)}</div>
      )}
    </div>
  );
};

export const Input = ({
  id,
  label,
  type = "text",
  labelAbove,
  containerClassName,
  error,
  ...props
}) => {
  const fileInput = useRef(null);

  const handleClick = () => {
    fileInput.current.click();
  };

  return type === "file" ? (
    <div className="file-input-container">
      {(label || labelAbove) && (
        <label
          htmlFor={id}
          className="label-above"
          onClick={handleClick}
          style={{ cursor: "pointer", fontSize: "1.5rem" }}
        >
          {label || labelAbove}
        </label>
      )}
      <input
        {...props}
        id={id}
        type={"file"}
        style={{ display: "none" }}
        ref={fileInput}
      />
    </div>
  ) : (
    <div
      className={`input-container full-width ${containerClassName} ${
        error && "error"
      } ${type === "checkbox" ? "checkbox" : type === "radio" ? "radio" : ""}`}
    >
      {labelAbove && (
        <label htmlFor={id} className="label-above">
          {labelAbove}
        </label>
      )}
      <input
        {...props}
        id={id}
        type={type}
        list=""
        onWheel={(event) => event.target.blur()}
      />
      {label && <label htmlFor={id}>{label}</label>}
    </div>
  );
};
