import React from "react";
import { useNavigate } from "react-router-dom";
import { isLogged } from "../../helpers/authorizationToken";
import { Button } from "../common";

const ErrorHandler = () => {
  const navigate = useNavigate();

  return (
    <div
      className="flex-column align-center justify-center"
      style={{ height: "calc(100vh - 75px)" }}
    >
      <h1>YOU MUST BE LOGGED IN TO ACCESS THIS PAGE</h1>
      <div className="flex" style={{ marginTop: "2rem" }}>
        <Button
          className="secondary"
          label="Log in"
          onClick={() => navigate("/login")}
          style={{ marginRight: "1rem" }}
        />
        <Button
          label="Register"
          onClick={() => navigate("/funding-application/register")}
        />
      </div>
    </div>
  );
};

const Guard = ({ children }) => {
  return isLogged() ? <>{children}</> : <ErrorHandler />;
};

export default Guard;
