
export function formatDate(inputDate) {
  // Parse input date string
  const date = new Date(inputDate);

  // Extract date components
  const year = date.getFullYear();
  const month = getMonthName(date.getMonth());
  const day = date.getDate();

  // Construct formatted date string
  const formattedDate = `${month} ${day}, ${year}`;

  return formattedDate;
}

export function getDateYear(inputDate) {
  // Parse input date string
  const date = new Date(inputDate);

  // Extract date components
  const year = date.getFullYear();

  return year;
}

export function getMonthName(monthNumber) {
  // Define months array
  const months = [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
  ];

  // Extract date components
  const month = months[monthNumber];

  return month;
}