import React from "react";
import "./Box.scss";

const Box = ({ animateIn = false, className = "", children, ...props }) => {
  return (
    <div
      className={`box ${className} ${animateIn ? "animateIn" : ""}`}
      {...props}
    >
      {children}
    </div>
  );
};

export default Box;
