import api from './api';

const url = '/uploads';

export const uploadFile = async ({ ...data }) =>
  api({
    url: url,
    method: 'post',
    headers: {
      'content-type': 'multipart/form-data',
    },
    data,
  });

export const downloadFile = async (id, type) =>
  api({
    url: `${url}/${type}/download/${id}`,
  });
