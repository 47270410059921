import React from "react";
import "./Loader.scss";

export const Loader = (props) => {
  return (
    <div className="loader-container" {...props}>
      <div className="loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export const LoaderIndicator = (props) => {
  return (
    <div className="loader-container indicator" {...props}>
      <div className="loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
