import React, { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { useNavigate } from "react-router-dom";
import { number, object, string } from "yup";
import {
  createFundingApplication,
  getCitizenshipStatuses,
  getDegrees,
  getEnrollmentStatuses,
  getSchoolYears,
} from "../../../api/funding-application";
import { Button, Form } from "../../../components/common";
import { isLogged } from "../../../helpers/authorizationToken";
import toast from "../../../helpers/toast";
import { useGetMe } from "../../../hooks";
import "./SchoolAndProgram.scss";
import SchoolAndProgramFormFields from "./SchoolAndProgramFormFields";

// const testDataDeclined = {
//   institution: "/institutions/1",
//   state: "/u_s_states/1",
//   major: "/majors/41",
//   degree: "/degrees/1",
//   enrollmentStatus: "/enrollment_statuses/1",
//   schoolYear: "/school_years/1",
//   graduation: "2022-11",
//   citizenshipStatus: "/citizenship_statuses/1",
//   amount: 12000,
// };

// const testData = {
//   institution: "/institutions/2273",
//   state: "/u_s_states/32",
//   major: "/majors/40",
//   degree: "/degrees/2",
//   enrollmentStatus: "/enrollment_statuses/1",
//   schoolYear: "/school_years/4",
//   graduation: "2023-11",
//   citizenshipStatus: "/citizenship_statuses/1",
//   amount: 5000,
// };

const getCascadingFieldsChanges = (name, value) => {
  let additionalChanges = {};

  switch (name) {
    case "institution": {
      if (!value) {
        additionalChanges = {
          state: "",
          major: "",
          degree: "",
          enrollmentStatus: "",
          schoolYear: "",
          graduation: "",
        };
      }
      break;
    }
    case "state": {
      if (!value) {
        additionalChanges = {
          major: "",
          degree: "",
          enrollmentStatus: "",
          schoolYear: "",
          graduation: "",
        };
      }
      break;
    }
    case "major": {
      if (!value) {
        additionalChanges = {
          degree: "",
          enrollmentStatus: "",
          schoolYear: "",
          graduation: "",
        };
      }
      break;
    }
    case "degree": {
      if (!value) {
        additionalChanges = {
          enrollmentStatus: "",
          schoolYear: "",
          graduation: "",
        };
      }
      break;
    }
    case "enrollmentStatus": {
      if (!value) {
        additionalChanges = {
          schoolYear: "",
          graduation: "",
        };
      }
      break;
    }
    case "schoolYear": {
      if (!value) {
        additionalChanges = {
          graduation: "",
        };
      }
      break;
    }
    default:
      break;
  }
  return additionalChanges;
};

const validationSchema = object({
  institution: object({
    "@id": string().required("College is a required field"),
  }).required("College is a required field"),
  state: object({
    "@id": string().required("State is a required field"),
    code: string().required("State is a required field"),
  }).required("State is a required field"),
  major: object({
    "@id": string().required("Major is a required field"),
  }).required("Major is a required field"),
  degree: string().required("Degree is a required field"),
  enrollmentStatus: string().required("Enrollment Status is a required field"),
  schoolYear: string().required("School Year is a required field"),
  graduationMonth: string().required("Graduation Month is a required field"),
  graduationYear: string().required("Graduation Year is a required field"),
  citizenshipStatus: string().required(
    "Citizenship Status is a required field"
  ),
  amount: number().required("Amount is a required field"),
});

// WORKFLOW STEP 1
const SchoolAndProgram = ({
  onProceed,
  isReadonly = false,
  latestApplication = null,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    amount: 5000,
  });
  const [dropdownsData, setDropdownsData] = useState({});
  const [dropdownsOptions, setDropdownsOptions] = useState({
    degree: [],
    enrollmentStatus: [],
    schoolYear: [],
    citizenshipStatus: [],
  });
  const { data: me, refetch } = useGetMe();

  const handleSubmit = async (data) => {
    // if is readonly mode - just change view
    if (isReadonly) {
      navigate("/dashboard#stepOneFinished");
      return;
    }

    let queryData = {
      ...data,
      graduation: `${data?.graduationYear}-${data?.graduationMonth}`,
      state: data?.state?.["@id"],
      institution: data?.institution?.["@id"],
      major: data?.major?.["@id"],
    };

    if (!queryData) {
      queryData = { ...dropdownsData };
    }

    try {
      await createFundingApplication(queryData).then(async (res) => {
        const applicationState = res?.data?.currentState;
        if (
          applicationState !== "softDeclined" &&
          applicationState !== "preApproved" &&
          applicationState !== "hardDeclined"
        ) {
          onProceed("stepOneFinished", true);
          toast.success("We are preparing your application, please wait.");
          window.location.reload();
        } else {
          localStorage.setItem("old_dashboard", true);
          // toast.error("The application has been declined");
          await refetch();
          navigate("/dashboard#stepOneFinished");
        }
      });
    } catch (e) {
      console.log("Error: ", e);
    }

    // Track the event
    TagManager.dataLayer({
      dataLayer: {
        event: "submitted_first_school_and_program",
        category: "Form Submission",
        action: "submit",
        label: "Submitted School And Program Form",
        value: 1,
      },
    });

    window.ttq.track("submitted_first_school_and_program");
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });

    (async () => {
      let degree = [];
      let enrollmentStatus = [];
      let schoolYear = [];
      let citizenshipStatus = [];

      await getDegrees().then(async (res) => {
        degree = res?.data?.["hydra:member"] || [];
        await getEnrollmentStatuses().then(async (res) => {
          enrollmentStatus = res?.data?.["hydra:member"] || [];
          await getSchoolYears().then(async (res) => {
            schoolYear = res?.data?.["hydra:member"] || [];
            await getCitizenshipStatuses().then(async (res) => {
              citizenshipStatus = res?.data?.["hydra:member"] || [];
            });
          });
        });
      });

      setDropdownsOptions({
        ...dropdownsOptions,
        degree,
        enrollmentStatus,
        schoolYear,
        citizenshipStatus,
      });
    })();
  }, []);

  useEffect(() => {
    if (!isLogged()) {
      navigate("/");
    }
  }, [isLogged]);

  useEffect(() => {
    document.body.classList.add("school-and-program");

    return () => {
      document.body.classList.remove("school-and-program");
    };
  }, []);

  return (
    <div className="step-wrapper school-and-program flex-column align-center">
      <h1 style={{ fontSize: "24px", fontWeight: 400 }}>School & Program</h1>
      {/* <h2 className="text-center" style={{ fontSize: "16px", fontWeight: 400 }}>
        Time to get to know what is your dream education and give you the best
        quote
      </h2> */}
      <Form
        onSubmit={handleSubmit}
        validationSchema={isReadonly ? object({}) : validationSchema}
        defaultValues={{ amount: 5000 }}
      >
        <SchoolAndProgramFormFields
          data={data}
          setData={setData}
          dropdownsOptions={dropdownsOptions}
          isReadonly={isReadonly}
          latestApplication={latestApplication}
        />
        <Button
          className="primary full-width xl"
          type="submit"
          label="Next step"
        />
        {/* TODO: Remove button when done with testing */}
        {/* <Button
          className="secondary full-width xl"
          type="submit"
          label="Next step (with test data & get accepted)"
          onClick={() => handleSubmit(testData)}
        /> */}
        {/* TODO: Remove button when done with testing */}
        {/* <Button
          className="secondary full-width xl"
          type="submit"
          label="Next step (with test data & get declined)"
          onClick={() => handleSubmit(testDataDeclined)}
        /> */}
      </Form>
    </div>
  );
};

export default SchoolAndProgram;
