import jwtDecode from "jwt-decode";

const key = "yeloAuthToken";

export const saveLoginToken = (token) => {
  localStorage.setItem(key, token);
};

export const getLoginToken = () => localStorage.getItem(key);

export const getJWTData = () => {
  let data = {};

  try {
    data = jwtDecode(getLoginToken() || "");
  } catch (error) {
    console.log("Error: ", error)
  }

  return data;
};

export const removeLoginToken = () => localStorage.removeItem(key);

export const isLogged = () => Boolean(getLoginToken());