import React, {useEffect, useState} from "react";
import { useFormContext } from "react-hook-form";
import { getFormErrorMsg } from "../../../helpers/formErrorMessages";
import { useFormSubmitStatus } from "../../../hooks";
import "./DateSelector.scss";

export const DateSelectorField = ({
  id,
  name,
  disabled,
  labelAbove,
  containerClassName,
  required,
  defaultMonth = "",
  defaultYear = "",
}) => {
  const {
    formState: { errors },
    setValue,
  } = useFormContext();
  const submitting = useFormSubmitStatus();

  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const currentYear = new Date().getFullYear() + 1;
  const years = Array.from({ length: currentYear + 10 - currentYear + 1 }, (_, index) => currentYear + index);

  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    setValue('graduationMonth', event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setValue('graduationYear', event.target.value);
  };

  useEffect(() => {
    if (defaultMonth) {
      setSelectedMonth(defaultMonth);
    }

    if (defaultYear) {
      setSelectedYear(defaultYear);
    }
  }, [defaultMonth, defaultYear])

  return (
    <div
      className={`input-container full-width ${containerClassName} date-selector ${
        Boolean(getFormErrorMsg(errors, name)) && "error"
      }${required ? " required" : ""}`}
    >
      {labelAbove && (
        <label htmlFor={id} className="label-above">
          {labelAbove}
        </label>
      )}
      <div className="select-container">
        <select
          id={`${id}-month`}
          onChange={handleMonthChange}
          value={selectedMonth}
          disabled={disabled || submitting}
        >
          <option value="">Select Month</option>
          {months.map((month) => (
            <option key={month.value} value={month.value}>
              {month.label}
            </option>
          ))}
        </select>
        <select
          id={`${id}-year`}
          onChange={handleYearChange}
          value={selectedYear}
          disabled={disabled || submitting}
        >
          <option value="">Select Year</option>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      {Boolean(getFormErrorMsg(errors, 'graduationYear')) && (
        <div className="error-hint">{getFormErrorMsg(errors, 'graduationYear')}</div>
      )}
      {Boolean(getFormErrorMsg(errors, 'graduationMonth')) && (
        <div className="error-hint">{getFormErrorMsg(errors, 'graduationMonth')}</div>
      )}
    </div>
  );
};