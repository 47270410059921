import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo-top.svg";
import { isLogged } from "../../helpers/authorizationToken";
import { useWindowSize } from "../../hooks";
import Button from "../common/button/Button";
import ProfileMenu from "./ProfileMenu";
import "./NavBar.scss";

const Navbar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const windowSize = useWindowSize();
  const breakpoints = {
    "1080p": 1920,
    tablet: 1024,
    smartphone: 780,
  };

  const handleToggle = () => {
    setMenuIsOpen((state) => !state);
  };

  const isHome = (url) => {
    const homeHrefs = [
      "/",
      "/#home",
      "/#our-fundings",
      "/#calculator",
      "/#about-us",
      "/#faq",
      "/blog",
    ];
    if (url.startsWith("/blog/")) {
      return true;
    }
    return homeHrefs.includes(url);
  };

  return (
    <header className={`siteHeader sticky${isHome(pathname) ? " home" : ""}`}>
      <nav className={`siteNavigation${menuIsOpen ? " open" : ""}`}>
        <a href="/#home" className="logo">
          <img src={logo} alt="Yelo" />
        </a>
        <ul className="siteNavigation__main">
        {isHome(pathname) && (
          <>
            <li>
              <a onClick={() => setMenuIsOpen(false)} href="/#home">Home</a>
            </li>
            <li>
              <a onClick={() => setMenuIsOpen(false)} href="/#how-it-works">How It Works</a>
            </li>
            <li>
              <a onClick={() => setMenuIsOpen(false)} href="/#calculator">Calculator</a>
            </li>
            <li>
              <a onClick={() => setMenuIsOpen(false)} href="/#faq">FAQ</a>
            </li>
            <li>
              <a onClick={() => setMenuIsOpen(false)} href="/blog">Resources</a>
            </li>
            <li>
              <a onClick={() => setMenuIsOpen(false)} href="/#about-us">About us</a>
            </li>
          </>
        )}
        </ul>
        <ul className="siteNavigation__auth">
          <li>
            {pathname !== "/login" && !isLogged() && (
              <Button
                className="secondary login"
                onClick={() => navigate("/login")}
                label="Log in"
              />
            )}
            {!isLogged() ||
              (pathname !== "/" && (
                <Button
                  className="tertiary"
                  onClick={() => navigate("/")}
                  label={
                    windowSize.width <= breakpoints.smartphone
                      ? "Home"
                      : "Back to home"
                  }
                  icon="back"
                />
              ))}
          </li>
          {!isLogged() ? (
            <li>
              <a  onClick={() => setMenuIsOpen(false)} href="/#apply-now">
                <Button className="primary applyNow" label="Apply now" />
              </a>
            </li>
          ) : (
            <li>
              <ProfileMenu />
            </li>
          )}
        </ul>
        {isHome(pathname) && (
          <div
            className={`burger${!menuIsOpen ? " open" : " close"}`}
            onClick={handleToggle}
          >
            <span>menu</span>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Navbar;
