import React, {useEffect, useState} from "react";
import { useFormContext } from "react-hook-form";
import { getFormErrorMsg } from "../../../helpers/formErrorMessages";
import { useFormSubmitStatus } from "../../../hooks";
import InputMask from "react-input-mask";
import "./Input.scss";

const SSNInputField = ({
  disabled,
  type = "text",
  name,
  defaultValue,
  id,
  label,
  labelAbove,
  containerClassName,
  inputMask,
  isReadonly = false,
  customErrorMsg = "This is not a valid social security number",
  ...rest
}) => {
  const {
    formState: { errors },
    watch,
    setValue,
    clearErrors,
    setError,
  } = useFormContext();

  const [currentValue, setCurrentValue] = useState(defaultValue || "");

  const handleChange = (event) => {
    setValue(name, event.target.value);
    setCurrentValue(event.target.value);
  };

  useEffect(() => {
    setCurrentValue(defaultValue);
  }, [defaultValue]);

  // const value = watch(name);
  const submitting = useFormSubmitStatus();

  return (
    <div>
      <div
        className={`input-container full-width ${containerClassName} ${
          Boolean(getFormErrorMsg(errors, name)) && "error"
        } ${
          type === "checkbox"
            ? "checkbox"
            : type === "radio"
            ? "radio"
            : type === "password"
            ? "password"
            : ""
        }${rest.required ? " required" : ""}${disabled ? " disabled" : ""}`}
      >
        {labelAbove && (
          <label htmlFor={id} className="label-above">
            {labelAbove}
          </label>
        )}
        <InputMask
          mask="999-99-9999"
          value={currentValue}
          onChange={(e) => {
            if(isReadonly) return;


            handleChange(e)

            if (
              e.target.value.replaceAll("-", "").replaceAll(/\s/g, "")
                .length === 9
            ) {
              clearErrors(name);
            } else {
              setError(name, {
                type: "custom",
                message: customErrorMsg,
              });
            }
          }}
          disabled={false}
          maskChar=" "
        >
          {() => (
            <input
              disabled={disabled || submitting}
              {...rest}
              id={id}
              type={type}
              list=""
              onWheel={(event) => event.target.blur()}
            />
          )}
        </InputMask>
      </div>
      {Boolean(getFormErrorMsg(errors, name)) && (
        <div className="error-hint">{getFormErrorMsg(errors, name)}</div>
      )}
    </div>
  );
};

export default SSNInputField;
