import React, { useEffect } from "react";
import { useWatch } from "react-hook-form";
import {
  getInstitutions,
  getMajors,
  getUSStates,
} from "../../../api/funding-application";
import {
  AutocompleteDropdownField,
  DateSelectorField,
  DropdownField,
  SliderField,
} from "../../../components/common";
import { currencyFormatter } from "../../../helpers/currencyFormat";

const SchoolAndProgramFormFields = ({ dropdownsOptions, isReadonly = false, latestApplication = null }) => {
  const formData = useWatch();

  let defaultAttributes = {};
  if (isReadonly) {
    defaultAttributes = {
      state: { defaultValue: latestApplication?.state?.name, readOnly: true },
      institution: { defaultValue: latestApplication?.institution?.name, readOnly: true },
      major: { defaultValue: latestApplication?.major?.type, readOnly: true },
      enrollmentStatus: { defaultValue: latestApplication?.enrollmentStatus?.type, readOnly: true },
      degree: { defaultValue: latestApplication?.degree?.type, readOnly: true },
      schoolYear: { defaultValue: latestApplication?.schoolYear?.type, readOnly: true },
      graduation: { disabled: true },
      citizenship: { defaultValue: latestApplication?.citizenshipStatus?.type, readOnly: true },
      slider: { defaultValue: latestApplication?.amount },
    }

    const dateObject = new Date(latestApplication?.graduation);
    if (latestApplication?.graduation && dateObject) {
      defaultAttributes.graduation = { defaultMonth: dateObject.getMonth() < 10 ? `0${dateObject.getMonth()}` : dateObject.getMonth(), defaultYear: dateObject.getFullYear(), disabled: true };
    }
  }

  return (
    <>
      <AutocompleteDropdownField
        id="state"
        name="state"
        placeholder="Start typing state"
        labelAbove="State"
        query={getUSStates}
        queryId="get-us-states"
        customErrorMsg="State is a required field"
        required
        infiniteScroll
        {...defaultAttributes?.state}
      />
      <AutocompleteDropdownField
        id="institution"
        name="institution"
        placeholder="Start typing college"
        labelAbove="College"
        autoComplete="off"
        query={getInstitutions}
        queryId="get-institutions"
        customErrorMsg="College is a required field"
        required
        infiniteScroll
        queryFilter={{state: formData?.state?.code || undefined}}
        {...defaultAttributes?.institution}
      />

      <AutocompleteDropdownField
        id="major"
        name="major"
        placeholder="Start typing major"
        labelAbove="Major"
        query={getMajors}
        queryId="get-majors"
        dropdownLabelField="type"
        customErrorMsg="Major is a required field"
        required
        infiniteScroll
        queryFilter={{institution: formData?.institution?.id || undefined}}
        {...defaultAttributes?.major}
      />

      <DropdownField
        id="enrollmentStatus"
        name="enrollmentStatus"
        placeholder="Select enrollment status"
        labelAbove="Enrollment Status"
        options={dropdownsOptions.enrollmentStatus}
        dropdownLabelField="type"
        required
        {...defaultAttributes?.enrollmentStatus}
      />

      <DropdownField
        id="degree"
        name="degree"
        placeholder="Select degree"
        labelAbove="Degree"
        dropdownLabelField="type"
        options={dropdownsOptions.degree.sort((a, b) =>
          a.type.toString().localeCompare(b.type.toString())
        )}
        required
        {...defaultAttributes?.degree}
      />

      <DropdownField
        id="schoolYear"
        name="schoolYear"
        placeholder="Select school year"
        labelAbove="School Year"
        options={dropdownsOptions.schoolYear}
        dropdownLabelField="type"
        required
        {...defaultAttributes?.schoolYear}
      />

      <DateSelectorField
        id="graduation"
        name="graduation"
        labelAbove="Expected Graduation Date"
        type="month"
        required
        {...defaultAttributes?.graduation}
      />
      <DropdownField
        id="citizenshipStatus"
        name="citizenshipStatus"
        placeholder="Select"
        labelAbove="Citizenship Status"
        options={dropdownsOptions.citizenshipStatus}
        dropdownLabelField="type"
        required
        {...defaultAttributes?.citizenship}
      />
      <div>
        <div
          style={{
            fontSize: "1.6rem",
            fontWeight: 500,
            paddingLeft: "2.5rem",
            marginTop: "2rem",
          }}
        >
          How much funding do you need?
          <span style={{ color: "#657df1" }}>&nbsp;*</span>
        </div>

        <div className="flex-column" style={{ paddingInline: '15px' }}>
          <SliderField name="amount" min={5000} max={15000} step={1000} disabled={isReadonly} />
          <div
            style={{
              fontSize: "1.4rem",
              fontWeight: 500,
              textAlign: 'center',
            }}
          >
            Amount: {currencyFormatter(isReadonly ? defaultAttributes?.slider.defaultValue : formData.amount)}
          </div>
        </div>
      </div>
    </>
  );
};

export default SchoolAndProgramFormFields;
