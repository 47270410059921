import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { requestPasswordReset } from "../../../../api/security";
import {
  Box,
  Button,
  Footer,
  Form,
  InputField,
} from "../../../../components/common";
import AuthorisationDecorations from "../../authorization-decorations/AuthorisationDecorations";
import "../Login.scss";

const validationSchema = object({
  email: string().required("Email is a required field"),
});

const PasswordReset = () => {
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (data) => {
    console.log("data: ", data);
    setSubmitted(true);
    try {
      await requestPasswordReset(data);
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  return (
    <>
      <main className="login">
        <div className="flex-column content">
          <h1>Password Reset</h1>
          <Box className="flex-column justify-center align-center">
            <Form onSubmit={handleSubmit} validationSchema={validationSchema}>
              <InputField
                id="email"
                name="email"
                placeholder="example@mail.com"
                labelAbove="E-mail"
              />
              <Button
                className="primary full-width xl"
                type="submit"
                label="Request password reset"
              />
              {submitted && (
                <p style={{ fontSize: "13px", textAlign: "center" }}>
                  If an account exists with this e-mail, an e-mail will be sent
                  with a link to reset your password.
                </p>
              )}
            </Form>
          </Box>
        </div>
        <AuthorisationDecorations />
      </main>
      <Footer />
    </>
  );
};

export default PasswordReset;
