import React, { useEffect, useRef, useState } from "react";
import { useIsInViewport, useWindowSize } from "../../../hooks";
import useIntersectionRatio from "../../../hooks/useIntersectionRatio/useIntersectionRatio";
import useScrollPosition from "../../../hooks/useScrollPosition/useScrollPosition";
import "./WhoWeAre.scss";

const WhoWeAre = ({
  title,
  // storyTitle,
  // storyShort,
  // missionTitle,
  // missionShort,
  content,
  firstImg,
  secondImg,
}) => {
  const mobileBreakpoint = 768;
  const windowSize = useWindowSize();
  const isInViewportThreshold = windowSize.width > mobileBreakpoint ? 0.5 : 0.2;
  const sectionRef = useRef(null);
  const isInViewport = useIsInViewport(sectionRef, isInViewportThreshold);
  const [showAnimation, setShowAnimation] = useState(false);

  const intersectionRatio = useIntersectionRatio(sectionRef);
  const [presentationStep, setPresentationStep] = useState(0);

  const scrollPosition = useScrollPosition();

  const parallaxMultiplier = windowSize > mobileBreakpoint ? 3 : 5;

  useEffect(() => {
    if (isInViewport) {
      setTimeout(() => setShowAnimation(isInViewport), 50);
    }
  }, [isInViewport]);

  // TODO: move logic to hook
  useEffect(() => {
    if (intersectionRatio > 0) {
      const elemRect = sectionRef.current.getBoundingClientRect();
      if (windowSize.width > mobileBreakpoint && elemRect.top > 0) {
        setPresentationStep(elemRect.top / 9);
      } else if (windowSize.width <= mobileBreakpoint && elemRect.bottom > 0) {
        setPresentationStep(elemRect.bottom / 50);
      }

      // console.log(windowSize, mobileBreakpoint);
    }
  }, [intersectionRatio, sectionRef, scrollPosition]);

  const calcMarginTop = () => {
    // console.log(presentationStep);
    if (presentationStep * parallaxMultiplier * 0.8 > 600) {
      // console.log(presentationStep*parallaxMultiplier);
      return -600;
    }
    // console.log('>>', Math.round(presentationStep*parallaxMultiplier*0.5));
    return -Math.round(presentationStep * parallaxMultiplier * 0.8);
  };

  return (
    <section
      className={`section section--whoWeAre${showAnimation ? " onScreen" : ""}`}
      ref={sectionRef}
    >
      <div className="section__innerWrapper">
        <article className="overDeco">
          <h2 className="animateIn">
            <a className="anchor" name="about-us">{title}</a>
          </h2>
          <article className="animateIn">
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          </article>
        </article>
        <div className="visuals">
          <div
            className="visual visual--1 animateIn"
            style={{ marginTop: `${calcMarginTop()}px` }}
          >
            <img src={`${process.env.REACT_APP_IMG_URL}/${firstImg}`} alt="" />
          </div>
          <div className="visual visual--2 animateIn">
            <img src={`${process.env.REACT_APP_IMG_URL}/${secondImg}`} alt="" />
            <div
              className="visual--2__yellowCirc"
              style={{ marginTop: `${calcMarginTop()}px` }}
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
