import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {currencyFormatter} from "../../../helpers/currencyFormat";
import {formatDate, getDateYear} from "../../../helpers/formatDate";
import checkmarkIcon from "../../../assets/icons/circle_checkmark.svg";
import React, {useState} from "react";
import "./dashboard_data.scss";
import {isNil} from "lodash";



const NewDashboard = ({latestApplication, me, latestApplicationState}) => {

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);


  // console.log(latestApplication)

  return <section className="dashboard-data-section">
    <div className="section__innerWrapper">
      <div className="dashboard-data-container">
        {/* dashboard-column */}
        <div className="dashboard-column">
          <div className="column-title">
            Personal information <span className={`open-close-button ${isOpen1 ? 'opened' : ''}`}
                                       onClick={() => setIsOpen1(!isOpen1)}></span>
          </div>
          <ul className={isOpen1 ? 'open' : ''}>
            <li>
              <span className="label">NAME</span>
              <span className="data">{`${me?.firstname} ${me?.lastname}`}</span>
            </li>
            <li>
              <span className="label">E-mail</span>
              <span className="data">{`${me?.email}`}</span>
            </li>
            <li>
              <span className="label">Phone</span>
              <span className="data">{`${me?.phone}`}</span>
            </li>
            {/*<li>*/}
            {/*  <span className="label">Address</span>*/}
            {/*  <span className="data">n/a</span>*/}
            {/*</li>*/}
            <li>
              <span className="label">birth date</span>
              <span className="data">{`${formatDate(latestApplication?.birthDate)}`}</span>
            </li>
            <li>
              <span className="label">Citizenship</span>
              <span className="data">{`${latestApplication?.citizenshipStatus?.type}`}</span>
            </li>
            <li>
              <span className="label">Linkedin url</span>
              <span className="data">{`${latestApplication?.linkedInUrl}`}</span>
            </li>
            <li>
              <span className="label">How did you hear about us?</span>
              <span className="data">{`${me?.how}`}</span>
            </li>
          </ul>
        </div>
        {/* End dashboard-column */}

        {/* dashboard-column */}
        <div className="dashboard-column">
          <div className="column-title">
            Education <span className={`open-close-button ${isOpen2 ? 'opened' : ''}`}
                            onClick={() => setIsOpen2(!isOpen2)}></span>
          </div>
          <ul className={isOpen2 ? 'open' : ''}>
            <li>
              <span className="label">INSTITUTION</span>
              <span className="data">{latestApplication?.institution?.name}</span>
            </li>
            <li>
              <span className="label">state</span>
              <span className="data">{latestApplication?.state?.name}</span>
            </li>
            <li>
              <span className="label">Enrollment</span>
              <span className="data">{latestApplication?.enrollmentStatus?.type}</span>
            </li>
            <li>
              <span className="label">degree</span>
              <span className="data">{latestApplication?.degree?.type}</span>
            </li>
            <li>
              <span className="label">school year</span>
              <span className="data">{latestApplication?.schoolYear?.type}</span>
            </li>
            <li>
              <span className="label">graduation</span>
              <span className="data">{getDateYear(latestApplication?.graduation)}</span>
            </li>
            <li>
              <span className="label">major</span>
              <span className="data">{latestApplication?.major?.type}</span>
            </li>
            <li>
              <span className="label">gpa</span>
              <span className="data">{latestApplication?.gpa}</span>
            </li>
            <li>
              <span className="label">number of interships</span>
              <span className="data">{latestApplication?.numberInternships}</span>
            </li>
            <li>
              <span className="label">Number of Curricular Activities</span>
              <span className="data">{latestApplication?.numberCurricularActivities}</span>
            </li>
            <li>
              <span className="label">Considering Further Education</span>
              <span className="data">{latestApplication?.consideringFurtherEducation ? 'Yes' : 'No'}</span>
            </li>
            <li>
              <span className="label">Other Student Loans Amount</span>
              <span className="data">{currencyFormatter(latestApplication?.otherStudentLoansAmount, 2)}</span>
            </li>
            <li>
              <span className="label">Other ISA Amount</span>
              <span className="data">{currencyFormatter(latestApplication?.otherISAOutstandingAmount, 2)}</span>
            </li>
          </ul>
        </div>
        {/* End dashboard-column */}

        {/* dashboard-column */}
        <div className="dashboard-column no-border">
          <div className="column-title">
            Financial Details <span className={`open-close-button ${isOpen3 ? 'opened' : ''}`}
                                    onClick={() => setIsOpen3(!isOpen3)}></span>
          </div>
          <ul className={isOpen3 ? 'open' : ''}>
            <li>
              <span className="label">Requested amount</span>
              <span className="data">{currencyFormatter(latestApplication?.amount)}</span>
            </li>
            <li>
              <span className="label">Аpproved amount</span>
              <span className="data">{currencyFormatter(latestApplication?.maxFundedAmount)}</span>
            </li>
            {latestApplicationState !== 'preApproved' ? ( <>
              <li>
                <span className="label flex align-center">
                  <span>maximum number of years</span>
                  <Tooltip
                    title="This is the maximum amount you will ever repay "
                    enterTouchDelay={0}
                  >
                    <HelpOutlineIcon fontSize={"small"}/>
                  </Tooltip>
                </span>
              <span className="data">10</span>
            </li>
            <li>
                <span className="label flex align-center">
                  <span>repayment cap</span>
                  <Tooltip
                    title="This is the maximum amount you will ever repay "
                    enterTouchDelay={0}
                  >
                    <HelpOutlineIcon fontSize={"small"}/>
                  </Tooltip>
                </span>
              <span className="data">{currencyFormatter(1.9 * latestApplication?.maxFundedAmount)}</span>
            </li>
            <li>
                <span className="label flex align-center">
                  <span>expected apr</span>
                  <Tooltip
                    title="This is the maximum amount you will ever repay "
                    enterTouchDelay={0}
                  >
                    <HelpOutlineIcon fontSize={"small"}/>
                  </Tooltip>
                </span>
              <span className="data">{Number(latestApplication?.apr) == 0
                ? "-"
                : (Number(latestApplication?.apr) * 100).toFixed(2) + "%"
              }</span>
            </li>
            <li>
                <span className="label flex align-center">
                  <span>maximum apr</span>
                  <Tooltip
                    title="This is the maximum amount you will ever repay "
                    enterTouchDelay={0}
                  >
                    <HelpOutlineIcon fontSize={"small"}/>
                  </Tooltip>
                </span>
              <span className="data">9.90%</span>
            </li>
            <li>
                <span className="label flex align-center">
                  <span>income share</span>
                  <Tooltip
                    title="This is the maximum amount you will ever repay "
                    enterTouchDelay={0}
                  >
                    <HelpOutlineIcon fontSize={"small"}/>
                  </Tooltip>
                </span>
              <span className="data">{((0.5 * latestApplication?.maxFundedAmount) / 1000).toFixed(2)}%</span>
            </li>
            <li>
                <span className="label flex align-center">
                  <span>minimum income threshold</span>
                  <Tooltip
                    title="This is the maximum amount you will ever repay "
                    enterTouchDelay={0}
                  >
                    <HelpOutlineIcon fontSize={"small"}/>
                  </Tooltip>
                </span>
              <span className="data">{currencyFormatter(30000)}/year</span>
            </li>
            <li>
                <span className="label flex align-center">
                  <span>monthly payment</span>
                  <Tooltip
                    title="This is the maximum amount you will ever repay "
                    enterTouchDelay={0}
                  >
                    <HelpOutlineIcon fontSize={"small"}/>
                  </Tooltip>
                </span>
              <span className="data">{currencyFormatter(latestApplication?.averageMonthlyPayment, 2)}</span>
            </li>
            {!isNil(latestApplication?.totalCosts) && (
              <li>
                  <span className="label flex align-center">
                    <span>total costs</span>
                    <Tooltip
                      title="This is the maximum amount you will ever repay "
                      enterTouchDelay={0}
                    >
                      <HelpOutlineIcon fontSize={"small"}/>
                    </Tooltip>
                  </span>
                <span className="data">{currencyFormatter(latestApplication?.totalCosts, 2)}</span>
              </li>
            )}
          </>) : (<></>) }
          </ul>
        </div>
          <div className="dashboard-bottom-row">
            <p><img alt="" src={checkmarkIcon}/> You are all done! Waiting for a YELO call.</p>
          </div>
        </div>
      </div>
    </section>
}

export default NewDashboard;