import React, { useState, useRef, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { currencyFormatter } from "../../../helpers/currencyFormat";
import { getFormErrorMsg } from "../../../helpers/formErrorMessages";
import { useFormSubmitStatus } from "../../../hooks";
import "./Input.scss";

export const CurrencyInputField = ({
  disabled,
  type = "text",
  name,
  defaultValue = '',
  id,
  label,
  labelAbove,
  containerClassName,
  ...rest
}) => {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const getFormattedVal = (value) => {
    const formattedValue = value.replace(/[^0-9.]/g, "");
    const valArr = formattedValue.split(".");

    // slice depending on largest number
    const finalValue = `${valArr[0].slice(0, 9)}${
      valArr.length > 1 ? `.${valArr[1]}` : ""
    }`;

    if (!value || isNaN(value)) {
      return null;
    }

    if (valArr.length > 1) {
      if (finalValue.endsWith(".")) {
        return `${currencyFormatter(Number(finalValue).toFixed(2), 2)}.`;
      } else {
        return currencyFormatter(Number(finalValue).toFixed(2), 2);
      }

    } else {
      return finalValue === ""
        ? null
        : currencyFormatter(Number(finalValue).toFixed(2), 2);
    }
  }

  const submitting = useFormSubmitStatus();

  const [showPassword, setShowPassword] = useState(false);
  const [val, setVal] = useState(defaultValue ? getFormattedVal(String(defaultValue)) : '');

  useEffect(() => {
    if (defaultValue !== '') {
      setVal(defaultValue);
    }
  }, [defaultValue]);

  const handleShowPassword = () => {
    // TODO: Keep focus on input field when show/hide password
    setShowPassword((state) => !state);
  };

  return (
    <div>
      <div
        className={`input-container full-width ${containerClassName} ${
          Boolean(getFormErrorMsg(errors, name)) && "error"
        } ${
          type === "checkbox"
            ? "checkbox"
            : type === "radio"
            ? "radio"
            : type === "password"
            ? "password"
            : ""
        }${rest.required ? " required" : ""}${disabled ? " disabled" : ""}`}
      >
        {labelAbove && (
          <label htmlFor={id} className="label-above">
            {labelAbove}
          </label>
        )}
        <input
          disabled={disabled || submitting}
          {...rest}
          // {...register(name)}
          value={val}
          onChange={(e) => {
            const { target } = e;
            const value = target.value;
            const formattedValue = value.replace(/[^0-9.]/g, "");
            const valArr = formattedValue.split(".");

            // slice depending on largest number
            const finalValue = `${valArr[0].slice(0, 9)}${
              valArr.length > 1 ? `.${valArr[1]}` : ""
            }`;

            if (!value || isNaN(value)) {
              setVal(null);
              setValue(name, null);
            }

            if (valArr.length > 1) {
              if (finalValue.endsWith(".")) {
                setVal(
                  `${currencyFormatter(Number(finalValue).toFixed(2), 2)}.`
                );
              } else {
                setVal(currencyFormatter(Number(finalValue).toFixed(2), 2));
              }

              setValue(name, Number(finalValue).toFixed(2));
            } else {
              setVal(
                finalValue === ""
                  ? null
                  : currencyFormatter(Number(finalValue).toFixed(2), 2)
              );
              setValue(
                name,
                !isNaN(Number(finalValue))
                  ? Number(finalValue).toFixed(2)
                  : null
              );
            }
          }}
          id={id}
          type={type !== "password" ? type : showPassword ? "text" : "password"}
          list=""
          onWheel={(event) => event.target.blur()}
        />
        {label && <label htmlFor={id}>{label}</label>}
        {type === "password" && (
          <span className="show-password-toggle" onClick={handleShowPassword}>
            show password
          </span>
        )}
      </div>
      {Boolean(getFormErrorMsg(errors, name)) && (
        <div className="error-hint">{getFormErrorMsg(errors, name)}</div>
      )}
    </div>
  );
};
