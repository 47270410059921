import { format, parseISO } from "date-fns";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { Footer } from "../../components/common";
import BasicBreadcrumbs from "../../components/common/breadcrumbs/Breadcrumbs";
import CustomChip from "../../components/common/chip/Chip";
import useGetBlogArticle from "../../hooks/useGetBlogArticle/useGetBlogArticle";
import BlogRelatedArticles from "../blog-related-articles/BlogRelatedArticles";
import "./BlogArticle.scss";

const BlogArticle = () => {
  const { slug } = useParams();
  const { data } = useGetBlogArticle({ slug });

  if (!data) {
    return null;
  }

  return (
    <>
      <main>
        <Helmet>
          <title>{data.title}</title>
          <meta name="description" content={data.summary} />
          <meta name="keywords" content={data.keywords} />
        </Helmet>
        <div className="section__innerWrapper">
          <div className="article__breadcrumb">
            <BasicBreadcrumbs data={data} />
          </div>
          <div className="article__header">
            <div className="article__header--content">
              <CustomChip data={data} />
              <h1>{data.title}</h1>
              <p className="article_summary">{data.summary}</p>
              <p className="article__date-time">
                {format(parseISO(data.publishedAt), "MMMM dd, yyyy")} &#x2022;{" "}
                {data.readingTime} min read
              </p>
              <p><i>{data.author}</i></p>
              <img src={`${process.env.REACT_APP_IMG_URL}/blog/${data.headerFilename}`} alt={data.headerFilenameAlt} className="article__image" />
              <div
                className="article__content"
                dangerouslySetInnerHTML={{ __html: data.description }}
              />
            </div>
          </div>
        </div>

        <BlogRelatedArticles />
      </main>
      <Footer />
    </>
  );
};

export default BlogArticle;
