import React from "react";
import { Helmet } from "react-helmet-async";
import Header from "../../components/blog/header/Header";
import { Footer } from "../../components/common";
import BasicTabs from "../../components/common/tabs/Tabs";

const Blog = () => {
  return (
    <>
      <main>
        <Helmet>
          <title>YELO FUNDING - Blog</title>
        </Helmet>
        {/* <Header /> */}
        <BasicTabs />
      </main>
      <Footer />
    </>
  );
};

export default Blog;
