import React, { createContext } from "react";

export const FormSubmitStatusContext = createContext({
  submitting: false,
});

export const FormSubmitStatusProvider = ({ children, submitting = false }) => {
  const contextValue = {
    submitting: submitting,
  };

  return (
    <FormSubmitStatusContext.Provider value={contextValue}>
      {children}
    </FormSubmitStatusContext.Provider>
  );
};
