import api from './api'

const url = '/funding_applications'

export const createFundingApplication = async (data) =>
    api({
        url: url,
        method: 'post',
        data,
    });

export const updateFundingApplication = async ({ id, data }) =>
    api({
        url: `${url}/${id}`,
        method: 'put',
        data,
    });

export const getInstitutions = async (params) =>
    api({
        url: '/institutions',
        params,
    });

export const getUSStates = async (params) =>
    api({
        url: '/u_s_states',
        params,
    });

export const getMajors = async (params) =>
    api({
        url: '/majors',
        params,
    });


export const getDegrees = async (params) =>
    api({
        url: '/degrees',
        params,
    });

export const getEnrollmentStatuses = async (params) =>
    api({
        url: '/enrollment_statuses',
        params,
    });

export const getSchoolYears = async (params) =>
    api({
        url: '/school_years',
        params,
    });

export const getCitizenshipStatuses = async (params) =>
    api({
        url: '/citizenship_statuses',
        params,
    });


export const getQuestionnaireURL = async () =>
    api({
        url: '/questionnaires/request',
    });


