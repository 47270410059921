import React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import "./Breadcrumbs.scss";

export default function BasicBreadcrumbs({ data }) {
  return (
    <div className='breadcrumb__container' role="presentation">
      <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
        <Link href="/blog">
          Resources
        </Link>
        <Typography color="text.primary"><span className="turnicate-container">{data.title}</span></Typography>
      </Breadcrumbs>
    </div>
  );
}