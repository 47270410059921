import { Slider } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { currencyFormatter } from "../../../helpers/currencyFormat";
import { useIsInViewport, useWindowSize } from "../../../hooks";
import { Button } from "../../common";
import Calculations from "./Calculations";
import "./Calculator.scss";

const calculate = (amount, startingSalary) => {
  const originationFee = 0.02;
  const salaryGrowth = 0.0;
  const paymentCap = 1.9;
  const inSchoolPeriod = 12;
  const gracePeriod = 6;
  const maximumApr = 0.099;
  const incomeRate = 0.005;

  const columns = [];
  for (let i = 0; i < 120; i++) {
    let status = "Incomplete";
    let salary = 0.0;
    let payment = 0.0;
    let cumulative = 0.0;
    let apr = 0.0;
    let discount = 0.0;
    let apr2 = 0.0;

    if (i == 0) {
      salary = startingSalary / 12;
      payment = salary < 2500 ? 0.0 : (salary * incomeRate * amount) / 1000;
      cumulative = payment + amount * originationFee;
      apr =
        (cumulative - amount) /
        amount /
        ((i + 1 + inSchoolPeriod + gracePeriod) / 12);
      discount = Math.min(
        0,
        ((maximumApr * (i + 1 + inSchoolPeriod + gracePeriod)) / 12) * amount +
          amount -
          cumulative
      );
      apr2 =
        (cumulative + discount - amount) /
        amount /
        ((i + 1 + inSchoolPeriod + gracePeriod) / 12);
    } else {
      if ([13, 25, 37, 49, 61, 73, 85, 97, 109].includes(i + 1)) {
        salary = columns[i - 1]["salary"] * 1 + salaryGrowth;
      } else {
        salary = columns[i - 1]["salary"];
      }

      payment =
        salary < 2500
          ? 0.0
          : Math.min(
              amount * paymentCap +
                amount * originationFee -
                columns[i - 1]["cumulative"],
              (salary * incomeRate * amount) / 1000
            );

      cumulative = payment + columns[i - 1]["cumulative"];

      if (cumulative >= amount * paymentCap + amount * originationFee) {
        status = "Complete";
      }

      if (cumulative > columns[i - 1]["cumulative"]) {
        apr =
          (cumulative - amount) /
          amount /
          ((i + 1 + inSchoolPeriod + gracePeriod) / 12);
      }

      if (
        apr >= maximumApr &&
        status === "Complete" &&
        columns[i - 1].status === "Incomplete"
      ) {
        discount = Math.min(
          0,
          ((maximumApr * (i + 1 + inSchoolPeriod + gracePeriod)) / 12) *
            amount +
            amount -
            cumulative
        );
      }

      apr2 =
        (cumulative + discount - amount) /
        amount /
        ((i + 1 + inSchoolPeriod + gracePeriod) / 12);
    }

    columns.push({
      status: status,
      salary: salary,
      payment: payment,
      cumulative: cumulative,
      apr: apr,
      discount: discount,
      apr2: apr2,
    });
  }

  let totalCosts = 0.0;
  let effectiveTerm = 0;
  let averageMonthlyPayment = 0.0;
  let effectiveApr = 0.0;

  let gatheredEffectiveApr = false;
  for (let i = 0; i < columns.length; i++) {
    totalCosts += columns[i]["payment"] + columns[i]["discount"];

    if (columns[i]["status"] !== "Incomplete") {
      if (!gatheredEffectiveApr) {
        effectiveApr = columns[i]["apr2"];
        gatheredEffectiveApr = true;
      }

      break;
    }

    averageMonthlyPayment += columns[i]["payment"];
    effectiveTerm++;
  }

  if (!gatheredEffectiveApr) {
    effectiveApr = columns[columns.length - 1]["apr2"];
  }

  // console.log('effectiveTerm', effectiveTerm);
  // console.log('averageMonthlyPayment', averageMonthlyPayment);

  averageMonthlyPayment = averageMonthlyPayment / effectiveTerm;
  totalCosts = totalCosts + amount * originationFee - amount;

  const payments = columns.map((el) => el.payment);

  // console.log({
  //   totalCosts,
  //   effectiveTerm,
  //   averageMonthlyPayment,
  //   effectiveApr,
  //   payments,
  // });

  return {
    totalCosts,
    effectiveTerm,
    averageMonthlyPayment,
    effectiveApr,
    payments,
  };
};

const fundingValueMax = 15000;
const fundingValueMin = 5000;
const startingSalaryMax = 150000;
const startingSalaryMin = 30000;

const Calculator = ({ title, short }) => {
  const [fundingValue, setFundingValue] = useState(10000);
  const [startingSalary, setStartingSalary] = useState(70000);
  // const [formHidden, setFormHidden] = useState(true);
  const [calculationsHidden, setCalculationsHidden] = useState(true);
  const [formData, setFormData] = useState();

  const windowSize = useWindowSize();
  const isInViewportThreshold = windowSize.width > 768 ? 0.5 : 0.2;
  const sectionRef = useRef(null);
  const isInViewport = useIsInViewport(sectionRef, isInViewportThreshold);
  const [showAnimation, setShowAnimation] = useState(false);
  const maxAPR = 9.9 / 100; // percentage

  const {
    totalCosts,
    effectiveTerm,
    averageMonthlyPayment,
    payments: totalPayments,
    effectiveApr: apr,
  } = useMemo(() => {
    return calculate(fundingValue, startingSalary);
  }, [fundingValue, startingSalary]);

  // const maxMonthlyPayment = useMemo(() => {
  //   return Math.max(...totalPayments) / 12;
  // }, [totalPayments, totalCosts]);

  // const firstMonthPayment = useMemo(() => {
  //   return totalPayments[0] / 12;
  // }, [totalPayments, totalCosts]);

  useEffect(() => {
    if (isInViewport) {
      setTimeout(() => setShowAnimation(isInViewport), 50);
    }
  }, [isInViewport]);

  const handleFormSubmit = async () => {
    // setFormHidden(true);

    setFormData({
      apr,
      maxAPR,
      effectiveTerm,
      // firstMonthPayment,
      // maxMonthlyPayment,
      averageMonthlyPayment,
      totalCosts,
      totalPayments,
    });

    // await calculateFunding({
    //   startingSalary: startingSalary,
    //   amount: fundingValue,
    // }).then(({ data }) => {
    //   setFormData(data);
    // });

    setCalculationsHidden(false);
  };

  return (
    <section
      className={`section section--calculator${
        showAnimation ? " onScreen" : ""
      }`}
      ref={sectionRef}
    >
      <div className="section__innerWrapper">
        <article className="overDeco">
          <h2 className="center animateIn">
            <a className="anchor animateIn" name="calculator">
              CALCULATOR
            </a>
          </h2>
          <h3 className="center animateIn">{title}</h3>
        </article>
        <div className="calculatorTool animateIn overDeco">
          <div className="step1">
            <div className="step1__slider-wrapper">
              <div className="left">
                <span className="hint">{short}</span>
                <Slider
                  aria-label="Default"
                  valueLabelDisplay="off"
                  value={fundingValue}
                  onChange={(e) => {
                    setCalculationsHidden(true);
                    setFundingValue(e.target.value);
                  }}
                  min={fundingValueMin}
                  max={fundingValueMax}
                  step={1000}
                  sx={{ color: "rgb(246,220,28)" }}
                />
                <div className="sliderMinMaxHint font-weight-medium">
                  <span className="minValue">{currencyFormatter(5000)}</span>
                  <span className="maxValue">
                    {currencyFormatter(fundingValueMax)}
                  </span>
                </div>
              </div>
              <div className="right">
                <div className="decorationIntersect">
                  {/* <span className="yellow">
                    <span
                      className="bubble blue"
                      style={{
                        transform: `translate(50%, -50%) scale(${
                          0.95 + fundingValue / fundingValueMax
                        })`,
                      }}
                    ></span>
                  </span> */}
                  <span
                    className="bubble lightblue"
                    style={{
                      transform: `translate(50%, -50%) scale(${
                        fundingValue === fundingValueMax
                          ? 2
                          : fundingValue === fundingValueMin
                          ? 1.2
                          : 1 + fundingValue / fundingValueMax
                      })`,
                    }}
                  ></span>
                </div>
                <span className="rangeSliderValue">
                  {currencyFormatter(fundingValue)}
                </span>
              </div>
            </div>
            <div className="step1__slider-wrapper">
              <div className="left">
                <span className="hint">Expected Starting Salary</span>
                <Slider
                  aria-label="Default"
                  valueLabelDisplay="off"
                  value={startingSalary}
                  onChange={(e) => {
                    setCalculationsHidden(true);
                    setStartingSalary(e.target.value);
                  }}
                  min={startingSalaryMin}
                  max={startingSalaryMax}
                  step={1000}
                  sx={{ color: "rgb(246,220,28)" }}
                />
                <div className="sliderMinMaxHint font-weight-medium">
                  <span className="minValue">{currencyFormatter(30000)}</span>
                  <span className="maxValue">
                    {currencyFormatter(startingSalaryMax)}
                  </span>
                </div>
              </div>
              <div className="right">
                <div className="decorationIntersect">
                  <span
                    className="bubble lightblue"
                    style={{
                      transform: `translate(50%, -50%) scale(${
                        startingSalary === startingSalaryMax
                          ? 2
                          : startingSalary === startingSalaryMin
                          ? 1.2
                          : 1 + startingSalary / startingSalaryMax
                      })`,
                    }}
                  ></span>
                </div>
                <span className="rangeSliderValue">
                  {currencyFormatter(startingSalary)}
                </span>
              </div>
            </div>
          </div>
          <Button
            className="primary full-width"
            id="calculate"
            onClick={handleFormSubmit}
          >
            Calculate
          </Button>
          <div className={`step3 ${calculationsHidden ? "hidden" : "active"}`}>
            <Calculations {...formData} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Calculator;
