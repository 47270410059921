import * as React from 'react';
import "./Chip.scss";


const CustomChip = ({ data }) => {
  const getCategoryColor = (category) => {
    switch (category) {
      case 'In the news':
        return 'green';
      case 'Press Release':
        return 'blue';
      case 'Blog':
        return 'orange';
      default:
        return 'default';
    }
  };

  const categoryColor = getCategoryColor(data.categoryTitle);

  return (
    <div className={`card__chip card__chip--${categoryColor}`}>{data.categoryTitle}</div>
  );
};

export default CustomChip;