import api from './api'

const url = '/users'

export const createUser = async (data) =>
    api({
        url,
        method: 'post',
        data,
        noAuth: true,
    });

export const getUser = async ({ id, ...data }) =>
    api({
        url: `${url}/${id}`,
        method: 'post',
        data,
    });

export const getUserQuestionnaire = async () =>
    api({
        url: `${url}/questionnaire/request`,
    });