import React, { useEffect } from "react";

const TCPAConsentDownloadPage = () => {
  useEffect(() => {
    const a = document.getElementById("download");
    a.click();
    window.close();
  }, []);

  return (
    <div>
      {/* <p>
        By checking this box, I expressly agree and consent to receive
        informational, marketing or promotional calls, or text messages from
        YELO Funding, Inc. or its affiliates (“YELO”) from an automatic
        telephone dialing system and/or prerecorded messages at the phone number
        I have provided in the website, regardless of this number being listed
        on any federal or state Do Not Call registry. I understand that my
        consent is not a condition of purchasing any goods or services. I
        understand that I may revoke this consent at any time by contacting YELO
        at <a href="mailto:info@yelofunding.com">info@yelofunding.com</a> or
        Reply “STOP” to any text messages to cancel.
      </p> */}
      <a href="/files/YELO_TCPA_Consent.pdf" id="download" download></a>
    </div>
  );
};

export default TCPAConsentDownloadPage;
