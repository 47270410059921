import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getBlogRelatedArticles } from '../../api/blog-related-articles';

export default function useGetBlogRelatedArticles(options) {
  const result = useQuery('get-blog-related-articles', () => getBlogRelatedArticles(options), {
    staleTime: 120 * 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  });

  const formattedResult = useMemo(
    () => ({
      ...result,
      data: result?.data?.data,
    }),
    [result],
  );

  return formattedResult;
}
