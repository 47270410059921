import api from './api'

const url = '/security'

export const requestVerificationCode = async () =>
    api({
        url: `${url}/request-verification-code`,
    });

export const verifyVerificationCode = async (data) =>
    api({
        url: `${url}/verify-verification-code`,
        method: 'post',
        data,
    });

export const login = async (data) =>
    api({
        url: `${url}/login`,
        method: 'post',
        data,
        noAuth: true
    });

export const requestPasswordReset = async (data) => api({
    url: `${url}/forgotten-password/request`,
    method: 'post',
    data,
});

export const verifyForgottenPassword = async (data) => api({
    url: `${url}/forgotten-password/verify`,
    method: 'post',
    data,
});

export const changeForgottenPassword = async (data) => api({
    url: `${url}/forgotten-password/set`,
    method: 'post',
    data,
});