import { SnackbarProvider } from "notistack";
import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import Routes from "./app/Routes";
import { NavBar } from "./components";
import { SnackbarUtilsConfigurator } from "./helpers/toast";

const queryClient = new QueryClient();

const App = () => {
  return (
    <SnackbarProvider
      autoHideDuration={3000}
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <SnackbarUtilsConfigurator />
      <div className="App">
        <QueryClientProvider client={queryClient}>
          <HelmetProvider>
            <BrowserRouter>
              <NavBar />
              <Routes />
            </BrowserRouter>
          </HelmetProvider>
        </QueryClientProvider>
      </div>
    </SnackbarProvider>
  );
};

export default App;
