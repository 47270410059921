import React from "react";
import { useFormContext } from "react-hook-form";
import { getFormErrorMsg } from "../../../helpers/formErrorMessages";
import { useFormSubmitStatus } from "../../../hooks";
import "./Input.scss";

export const TextArea = ({
   disabled,
   name,
   defaultValue,
   id,
   label,
   labelAbove,
   containerClassName = '',
   showErrors = true,
   errorClassName = 'error',
   ...rest
  }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const submitting = useFormSubmitStatus();

  const hasError = Boolean(getFormErrorMsg(errors, name));

  return (
    <div>
      <div
        className={`input-container full-width ${containerClassName} ${
          Boolean(getFormErrorMsg(errors, name)) && errorClassName
        }`}
      >
        {labelAbove && (
          <label htmlFor={id} className="label-above">
            {labelAbove}
          </label>
        )}
        <textarea
          disabled={disabled || submitting}
          {...rest}
          {...register(name)}
          id={id}
          onWheel={(event) => event.target.blur()}
        />
        {label && <label htmlFor={id}>{label}</label>}
      </div>
      {hasError && showErrors && (
        <div className="error-hint">{getFormErrorMsg(errors, name)}</div>
      )}
    </div>
  );
};