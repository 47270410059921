import React, { useState } from 'react';
import { Button, Form, InputField } from "../../common";
import './ContactUs.scss';
import checkIcon from "../../../assets/icons/check_circle_yellow.svg";
import {object, string} from "yup";
import {TextArea} from "../input/TextArea";

export const validationSchema = object({
    fullName: string().required("Full name is a required field"),
    email: string()
      .email("Please enter a valid email")
      .trim()
      .required("E-mail is a required field"),
    message: string().required("Message is a required field"),
});

const STEP_ONE = 1;
const STEP_TWO = 2;

const ContactUs = () => {
    const [visible, setVisible] = useState(false);
    const [step, setStep] = useState(STEP_ONE);

    const onClick = () => handleVisibility(!visible)


    const handleVisibility = (visible = false) => {

      if (visible === false && step === STEP_TWO) {
        setStep(STEP_ONE)
      }

      setVisible(visible)
    }

    const handleSubmit = async (data) => {
        const payload = {
            email: data.email,
            fullName: data.fullName,
            message: data.message,
        };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/contact-us`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Error submitting form");
      }

      setStep(STEP_TWO);
    }

    return (
      <>
          <span onClick={onClick} className="applyNowBtn">
              <span>Contact us</span>
          </span>
          {(visible) && (
            <div className='popin-form-container'>
                <button className='close-btn' onClick={onClick}>&times;</button>
                {step === STEP_ONE && (
                  <div className='form-step-1'>
                      <div className='text-content'>
                          <h2>Contact us</h2>
                          <p>Drop your details here, and we'll do our best to assist you ASAP!</p>
                      </div>

                      <Form onSubmit={handleSubmit} validationSchema={validationSchema}>
                          <InputField
                            id="fullName"
                            name="fullName"
                            type="text"
                            placeholder="First and last name"
                            labelAbove={"First and last name"}
                            showErrors={false}
                            errorClassName="input-error"
                          />
                          <InputField
                            id="email"
                            name="email"
                            placeholder="E-mail"
                            labelAbove={"E-mail"}
                            showErrors={false}
                            errorClassName="input-error"
                          />
                          <TextArea
                            placeholder="Message"
                            name="message"
                            showErrors={false}
                            errorClassName="input-error"
                          />
                          <Button
                            className="button primary xl full-width"
                            label="Send message"
                            type="submit"
                          />
                      </Form>
                  </div>
                )}
                {step === STEP_TWO && (
                    <div className="step-2-container">
                        <img src={checkIcon} alt=""/>
                        <h2>Thank you for reaching out.</h2>
                        <p>Someone from our team will get back to you shortly.</p>
                    </div>
                )}
            </div>
          )}
      </>
    );
}

export default ContactUs;