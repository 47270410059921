import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import React, { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormSubmitStatusProvider } from "../../../context/FormSubmitStatusProvider";

const trimObjectValues = (source, skip) => {
  let result = {};
  Object.keys(source).forEach((key) => {
    if (typeof source[key] === "string" && key !== skip) {
      result[key] = source[key].trim();
      return;
    }

    result[key] = source[key];
  });

  return result;
};

const Form = ({
  children,
  onSubmit,
  validationSchema,
  defaultValues,
  submitting,
  hide = false,
  ...rest
}) => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues || {},
  });

  const submitHandler = useCallback(
    (data) => {
      onSubmit(trimObjectValues(data, "plainPassword"));
    },
    [onSubmit]
  );

  useEffect(() => {
    if (defaultValues) {
      methods.reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <FormSubmitStatusProvider submitting={submitting}>
      <FormProvider {...methods}>
        <Box
          sx={{
            position: "relative",
            display: hide ? "none" : "flex",
            flexDirection: "column",
            gap: "2rem",
          }}
          className="form-container"
          component="form"
          onSubmit={(event) => {
            event.preventDefault();
            event.stopPropagation();
            methods.handleSubmit(submitHandler)(event);
          }}
          {...rest}
          noValidate
        >
          {children}
          {/* {submitting && <LoadingContainer />} */}
        </Box>
      </FormProvider>

      {/* Uncomment DevTool component in order to debug ReactHookForms */}
      {/* <DevTool control={methods.control} /> */}
    </FormSubmitStatusProvider>
  );
};

export default Form;
