import api from './api'

const url = '/blogs/'

export const getIdFromSlug = (slug) => slug.substring(slug.lastIndexOf("-") + 1);

export const getBlogArticle = async (data) =>
    api({
        url: url + getIdFromSlug(data.slug),
        data,
    });