import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { getFormErrorMsg } from "../../../helpers/formErrorMessages";
import { useFormSubmitStatus } from "../../../hooks";
import InputMask from "react-input-mask";
import "./Input.scss";

export const PhoneInputField = ({
  disabled,
  type = "text",
  name,
  defaultValue,
  id,
  label,
  labelAbove,
  containerClassName,
  inputMask,
  readOnly= false,
  customErrorMsg = "Phone is not a valid US number",
  ...rest
}) => {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
    setError,
  } = useFormContext();

  const value = watch(name);

  const submitting = useFormSubmitStatus();

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    // TODO: Keep focus on input field when show/hide password
    setShowPassword((state) => !state);
  };

  return (
    <div>
      <div
        className={`input-container full-width ${containerClassName} ${
          Boolean(getFormErrorMsg(errors, name)) && "error"
        } ${
          type === "checkbox"
            ? "checkbox"
            : type === "radio"
            ? "radio"
            : type === "password"
            ? "password"
            : ""
        }${rest.required ? " required" : ""}${disabled ? " disabled" : ""}`}
      >
        {labelAbove && (
          <label htmlFor={id} className="label-above">
            {labelAbove}
          </label>
        )}
        <InputMask
          mask="+1 (999) 999 9999"
          value={value}
          onChange={(e) => {
            setValue(name, e.target.value);
            if (
              e.target.value
                .replace("(", "")
                .replace(")", "")
                .replace(/\s/g, "").length === 12
            ) {
              clearErrors(name);
            } else {
              setError(name, {
                type: "custom",
                message: customErrorMsg,
              });
            }
          }}
          disabled={false}
          maskChar=" "
        >
          {() => (
            <input
              disabled={disabled || submitting}
              {...rest}
              id={id}
              type={type}
              list=""
              onWheel={(event) => event.target.blur()}
            />
          )}
        </InputMask>
      </div>
      {Boolean(getFormErrorMsg(errors, name)) && (
        <div className="error-hint">{getFormErrorMsg(errors, name)}</div>
      )}
    </div>
  );
};
