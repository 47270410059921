import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import * as React from "react";
import useGetBlog from "../../../hooks/useGetBlog/useGetBlog";
import useGetBlogCategories from "../../../hooks/useGetBlogCategories/useGetBlogCategories";
import CustomCard from "../card/Card";
import "./Tabs.scss";

function CustomTabPanel(props) {
  const { children, heading, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {heading}
      {value === index && (
        <Box className="tabs__content-contaner">
          <div className="card__container">{children}</div>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function orderTabs(tabs, order) {
  const orderMap = new Map(order.map((name, index) => [name, index]));
  return tabs.sort((a, b) => orderMap.get(a.name) - orderMap.get(b.name));
}

export default function BasicTabs() {
  const [categoryId, setCategoryId] = React.useState();
  const [page, setPage] = React.useState(1);
  const { data, loading } = useGetBlog({ categoryId, page }); // Assuming the useGetBlog hook returns a 'loading' state
  const { data: dataCategory } = useGetBlogCategories();
  const [value, setValue] = React.useState(0); // Default tab index
  const allBlogs = data;
  const blogsMeta = data?.[data.length - 1];

  const hasMore =
    blogsMeta?.meta.totalItems >
    blogsMeta?.meta.itemsPerPage * blogsMeta?.meta.currentPage;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleLoadMore = () => {
    setPage((page) => page + 1);
  };

  const tabs = [{ name: "All articles", id: null }];
  if (dataCategory?.length > 0) {
    tabs.push(...dataCategory);
  }

  const orderedTabs = orderTabs(tabs, [
    "White Paper",
    "In the News",
    "Press Release",
    "Blog",
    "All articles",
  ]);

  React.useEffect(() => {
    setCategoryId(orderedTabs[value].id);
  }, [orderedTabs, value]);

  return (
    <section className="section__innerWrapper">
      <Box className="tabs__container">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs"
            className="tabs__buttons"
          >
            {orderedTabs.map((tab, index) => (
              <Tab key={index} label={tab.name} {...a11yProps(index)} />
            ))}
          </Tabs>
        </Box>
        {orderedTabs.map((_, index) => (
          <CustomTabPanel
            key={index}
            value={value}
            index={index}
            heading={
              _.title == "" ? null : (
                <Box className="tabs-intro-content">
                  <h2>{_.title}</h2>
                  <p>{_.summary ?? ""}</p>
                </Box>
              )
            }
          >
            {blogsMeta?.meta.totalItems === 0 ? (
              <p className="no-articles">No articles in this category.</p>
            ) : (
              allBlogs?.map((blogs) =>
                blogs?.data.map((article) => (
                  <CustomCard key={article.id} data={article.attributes} />
                ))
              )
            )}
          </CustomTabPanel>
        ))}
        {hasMore && (
          <button className="primary tabs__load-more" onClick={handleLoadMore}>
            Load more articles
          </button>
        )}
      </Box>
      {loading && <p>Loading...</p>}
    </section>
  );
}
