import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logoutIcon from "../../assets/icons/logout.svg";
import profileIcon from "../../assets/icons/profile.svg";
import { removeLoginToken } from "../../helpers/authorizationToken";
import { useGetMe } from "../../hooks";
import { Button } from "../common";
import "./ProfileMenu.scss";

export default function ProfileMenu() {
  const { data: me, refetch } = useGetMe();
  const { firstname, lastname } = me || {};
  const [anchorEl, setAnchorEl] = useState();
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      {firstname && lastname && (
        <div
          className="flex align-center justify-center"
          style={{ cursor: "pointer" }}
          onClick={handleClick}
        >
          <Button
            className="primary circle"
            label={`${firstname[0]}${lastname[0]}`}
          />
        </div>
      )}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            borderRadius: "20px",
            color: "#203864",
            mt: 1.5,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => navigate("/dashboard")}>
          <ListItemIcon>
            <img src={profileIcon} alt="" />
          </ListItemIcon>
          <span className="option-label">My account</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeLoginToken();
            navigate("/");
          }}
        >
          <ListItemIcon>
            <img src={logoutIcon} alt="" />
          </ListItemIcon>
          <span className="option-label">Log out</span>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
