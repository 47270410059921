import React, { useEffect, useState, useMemo } from "react";

const useIsInViewport = (ref, threshold = 0) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting)
      }, {
        root: null, 
        rootMargin: '0px 0px 0px 0px',
        threshold: threshold
      }
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer, threshold]);

  return isIntersecting;
};

export default useIsInViewport;
