import React, { useEffect, useRef, useState } from "react";
import { useIsInViewport, useWindowSize } from "../../../hooks";
import "./HowWeCompare.scss";

const HowWeCompare = ({ title, short, table }) => {
  const windowSize = useWindowSize();
  const isInViewportThreshold = windowSize.width > 768 ? 0.5 : 0.2;
  const sectionRef = useRef(null);
  const isInViewport = useIsInViewport(sectionRef, isInViewportThreshold);
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    if (isInViewport) {
      setTimeout(() => setShowAnimation(isInViewport), 50);
    }
  }, [isInViewport]);

  return (
    <section
      className={`section section--howWeCompare${
        showAnimation ? " onScreen" : ""
      }`}
      ref={sectionRef}
    >
      <div className="section__innerWrapper">
        <article className="overDeco">
          <h2 className="animateIn">{title}</h2>
          <p className="animateIn">{short}</p>
        </article>
        <div className="table animateIn overDeco">
          <div className="table__head">
            <span>YELO</span>
            <span>Private &amp; Plus Loans</span>
          </div>
          <div className="table__body">
            <ul>
              {table?.map((el, index) => {
                const class1 = 'isas-' + el.isas.split('_').pop().toString().toLowerCase();
                const class2 = 'trad-loan-' + el['trad-loan'].split('_').pop().toString().toLowerCase();

                return (<li key={index} className={`${class1} ${class2}`}>
                  <span>{el.text}</span>
                </li>);
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowWeCompare;
