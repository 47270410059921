import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import confettiIcon from "../../../assets/icons/confetti.svg";
import { Button } from "../../../components/common";
import "./Disbursement.scss";

const Disbursement = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add("disbursement");
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });

    return () => {
      document.body.classList.remove("disbursement");
    };
  }, []);

  return (
    <div
      className="flex-column align-center justify-center disbursement"
      style={{ gap: "2rem" }}
    >
      {/* <img src={confettiIcon} alt="" /> */}
      <lord-icon
        src="/animated-icons/confetti-flat.json"
        trigger="loop"
        delay="1000"
        colors="primary:#f9df1c,secondary:#7adba1,tertiary:#657df1,quaternary:#ff9fb6"
        style={{ width: "11.2rem", height: "11.2rem" }}
      ></lord-icon>
      <h1>
        You are all set! Thank you for applying.
        <br />
        Someone from YELO will contact you shortly.
      </h1>
      <Button
        className="primary xl"
        label="Back to my profile"
        onClick={() => navigate("/dashboard")}
      />
    </div>
  );
};

export default Disbursement;
