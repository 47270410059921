import React from "react";
import "./Button.scss";

const Button = ({ label, className = "primary", children, ...props }) => {
  return (
    <button className={className + (props.icon ? " icon" : "")} {...props}>
      {props.icon && <i className={props.icon}></i>}
      {label}
      {children && children}
    </button>
  );
};

export default Button;
