import React, { useEffect, useState } from "react";
import useScrollPosition from "../../../hooks/useScrollPosition/useScrollPosition";
import "./AuthorisationDecorations.scss";

const AuthorisationDecorations = () => {
  const [presentationStep, setPresentationStep] = useState(0);
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    setPresentationStep(scrollPosition);
  }, [scrollPosition]);

  return (
    <div className="authorisation-decorations">
      <div className="d1">
        <div className="big circle blue circle">
          <div className="yellow circle"></div>
          <div className="light-blue circle"></div>
        </div>
      </div>
      <div className="d2 light-blue circle"></div>
      <div className="d3 yellow circle"></div>

      <div className="d4 half circle">
        <div className="yellow circle"></div>
      </div>

      <div className="d5 half circle">
        <div className="light-blue circle"></div>
      </div>

      <div className="d6 yellow circle"></div>

      <div className="d7 light-blue outline circle"></div>
      <div className="d8 light-blue outline circle"></div>

      <div className="d9 light-blue circle"></div>
    </div>
  );
};

export default AuthorisationDecorations;
