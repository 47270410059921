import { useWindowSize } from "../../../hooks";
import "./Hero.scss";
import heroImage from "../../../assets/images/hero.png";
import bubblesTop from "../../../assets/images/BubbleGroup2.svg";
import bubblesBottom from "../../../assets/images/BubbleGroup1.svg";

const Hero = ({ fileName, short, title }) => {
  const windowSize = useWindowSize();


  function isMobile() {
    const mobileBreakpoint = 768;
    return windowSize.width <= mobileBreakpoint;
  }

  return (
    <section id="home" className="section section--hero static--hero">
      <img src={bubblesTop} alt="" className="bubbles-top" />
      <img src={bubblesBottom} alt="" className="bubbles-bottom" />
      <div className="section__innerWrapper">
        <article>
          <h1>{title}</h1>
          <p>{short.split("<br>").join("\n")}</p>
          <a href="#apply-now" title="Apply now" className="apply-now-button">
            <button className="button primary">
              Apply now
            </button>
          </a>
        </article>

        <img
          className="heroImage"
          // src={`${process.env.REACT_APP_IMG_URL}/${fileName}`}
          src={heroImage}
          alt=""
        />
        <a href="#apply-now" className="applyNowBtn">
          <span>Contact us</span>
        </a>
      </div>
    </section>
  );
};

export default Hero;
