import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getBlogCategories } from '../../api/blog-categories';

export default function useGetBlogCategories(options) {
  const formatData = (res) => {

    return res.data.data.map(item => ({
      id: item.attributes._id,
      name: item.attributes.category,
      title: item.attributes.title ?? '',
      summary: item.attributes.summary ?? '',
    }));
  };

  const result = useQuery('get-blog-article', () => getBlogCategories(options), {
    staleTime: 120 * 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: formatData,
    ...options,

  });

  return result;
}
