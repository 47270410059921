import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useIsInViewport, useWindowSize } from "../../../hooks";
import "./AboutUs.scss";

const ArrowStyles = {
  color: "#F9DF1C",
  fontSize: "5rem",
};

const NextArrow = ({ className, onClick }) => (
  <ArrowForwardIosIcon
    className={className}
    sx={{ ...ArrowStyles, transform: "translate(3rem, -50%)" }}
    onClick={onClick}
  />
);

const PrevArrow = ({ className, onClick }) => (
  <ArrowBackIosNewIcon
    className={className}
    sx={{ ...ArrowStyles, transform: "translate(-3rem, -50%)" }}
    onClick={onClick}
  />
);

const slickSettings = {
  dots: true,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 3000,
  infinite: true,
  speed: 400,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
  ],
};

const AboutUs = ({ title, featuredIn }) => {
  const windowSize = useWindowSize();
  const isInViewportThreshold = windowSize.width > 768 ? 0.5 : 0.2;
  const sectionRef = useRef(null);
  const isInViewport = useIsInViewport(sectionRef, isInViewportThreshold);
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    if (isInViewport) {
      setShowAnimation(isInViewport);
    }
  }, [isInViewport]);

  return (
    <section
      className={`section section--aboutUs${showAnimation ? " onScreen" : ""}`}
      ref={sectionRef}
    >
      <div className="section__innerWrapper">
        <article className="overDeco">
          <h3 className="center animateIn">{title}</h3>
        </article>
        <Slider {...slickSettings}>
          {featuredIn?.map((feat, index) => (
            <div className="slide-wrapper" key={feat.id || index}>
              <img
                src={`${process.env.REACT_APP_IMG_URL || ""}/${feat.img}`}
                alt="Featured"
                onClick={() => window.open(feat.link)}
              />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default AboutUs;
