import { Slider } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import { getFormErrorMsg } from "../../../helpers/formErrorMessages";

export const SliderField = ({ name, ...props }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <Slider
        aria-label="Default"
        valueLabelDisplay="off"
        name={name}
        {...register(name)}
        {...props}
        sx={{ color: "rgb(246,220,28)" }}
      />
      {Boolean(getFormErrorMsg(errors, name)) && (
        <div style={{ color: "red", marginLeft: "2rem" }}>
          {getFormErrorMsg(errors, name)}
        </div>
      )}
    </>
  );
};

export const SliderComponent = (props) => {
  return (
    <Slider
      aria-label="Default"
      valueLabelDisplay="off"
      {...props}
      sx={{ color: "rgb(246,220,28)" }}
    />
  );
};
