import axios from 'axios';
import { getLoginToken, isLogged, removeLoginToken } from '../helpers/authorizationToken'
import toast from '../helpers/toast';

const api = axios.create({ baseURL: process.env.REACT_APP_API_URL });

api.interceptors.request.use((config) => {
  const token = getLoginToken();
  if (token && config.headers && !config.noAuth) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  delete config.noAuth
  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log('error', error)

    const errorData =
      error?.response?.data?.message ||
      error?.response?.data?.detail ||
      error?.response?.data?.['hydra:description'] ||
      error?.response?.data?.violations?.[0]?.message ||
      'Unknown error';

    if (
      error?.response?.status === 401 || error?.response?.status === 403 && error?.response?.data?.message === 'Expired JWT Token'

    ) {
      removeLoginToken()
      window.location.replace('/');
    }

    toast.error(errorData)
    return Promise.reject(error);

  }
);

export default api;
