import React, { useEffect, useState, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useQuery } from "react-query";
import { getFormErrorMsg } from "../../../helpers/formErrorMessages";
import { useFormSubmitStatus } from "../../../hooks";
import "./AutocompleteDropdown.scss";

export const AutocompleteDropdownField = ({
  id,
  label,
  // value,
  // onChange,
  defaultValue = "",
  disabled,
  readOnly = false,
  type = "text",
  labelAbove,
  autocomplete,
  name,
  query,
  queryId,
  queryFilter,
  onAutocompleteSelection = () => {},
  dropdownLabelField = "name",
  customErrorMsg = "This is a required field",
  required,
  infiniteScroll = false,
  ...props
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState("");
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [page, setPage] = useState(1);
  const bodyRef = useRef();
  const scrollableContentRef = useRef();
  const {
    control,
    register,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useFormContext();

  const submitting = useFormSubmitStatus();

  useEffect(() => {
    if (defaultValue) {
      setSearch(defaultValue);
    }
  }, [defaultValue]);

  const handleScroll = (event) => {
    // event.stopPropagation();
    // event.preventDefault();

    // console.log(bodyRef.current.scrollTop + bodyRef.current.clientHeight);
    // console.log(scrollableContentRef.current.clientHeight - 100);

    setIsScrolledToBottom(
      bodyRef.current.scrollTop + bodyRef.current.clientHeight >=
        scrollableContentRef.current.clientHeight - 100
    );
  };

  useEffect(() => {
    if (infiniteScroll && isScrolledToBottom) {
      setPage(page + 1);
    }
  }, [isScrolledToBottom]);

  const { data, isLoading, isFetching } = useQuery(
    [queryId, infiniteScroll ? { keyword: search, page, ...queryFilter } : { keyword: search, ...queryFilter }],
    () => {
      if (query) {
        return query(
          infiniteScroll ? { keyword: search, page, ...queryFilter } : { keyword: search, ...queryFilter }
        );
      }
      return;
    }
  );

  const handleFocus = () => {
    if (readOnly) return;
    // props.onFocus && props.onFocus();
    setShowSuggestions(true);
  };

  const handleOnBlur = (e) => {
    // props.onBlur && props.onBlur();
    // setShowSuggestions(false);

    if (options.findIndex((el) => el.name === search) === -1) {
      // setValue(name, "");
      // setAutocompleteValue("");
    }

    setShowSuggestions(false);
  };

  const handleClick = (e) => {
    if (e.target.id !== id && e.target.className !== "option") {
      handleOnBlur();
    }
  };

  useEffect(() => {
    if (data?.data?.["hydra:member"]) {
      if (infiniteScroll && page > 1) {
        const newOptions = [];
        data?.data?.["hydra:member"].map((el) => {
          if (!options.some((x) => x.id === el.id)) {
            newOptions.push(el);
          }
        });

        setOptions([...options, ...newOptions]);
      } else {
        setOptions(data?.data?.["hydra:member"]);
      }
    }
  }, [data]);

  useEffect(() => {
    document.addEventListener("click", handleClick);
  }, []);

  return (
    <Controller
      control={control}
      name={name}
      render={(field) => (
        <div
          className={`input-container full-width auto-complete ${
            Boolean(getFormErrorMsg(errors, name)) && "error"
          }${required ? " required" : ""}`}
        >
          {labelAbove && (
            <label htmlFor={id} className="label-above">
              {labelAbove}
            </label>
          )}
          <input
            id={id}
            type={type}
            name={name}
            disabled={disabled || submitting}
            readOnly={readOnly}
            {...props}
            autoComplete="off"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              setPage(1);
              setValue(name, {});
              setError(name, {
                type: "custom",
                message: customErrorMsg,
              });
            }}
            list=""
            onFocus={handleFocus}
            className={showSuggestions ? "show-suggestions" : ""}
          />
          {showSuggestions && (
            <div className="dropdown-suggestions">
              <div
                className="inner-wrapper"
                ref={bodyRef}
                onScroll={handleScroll}
              >
                <div className="dropdown-content" ref={scrollableContentRef}>
                  {options?.map((option) => (
                    <div
                      className="option"
                      key={option.id}
                      onClick={() => {
                        onAutocompleteSelection(
                          option,
                          name,
                          option?.[dropdownLabelField]
                        );
                        setValue(name, option || {});
                        clearErrors(name);
                        setSearch(option?.[dropdownLabelField]);
                        setShowSuggestions(false);
                      }}
                    >
                      {option?.[dropdownLabelField]}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {Boolean(getFormErrorMsg(errors, name)) && (
            <div className="error-hint">{getFormErrorMsg(errors, name)}</div>
          )}
        </div>
      )}
    />
  );
};

export const AutocompleteDropdown = ({
  id,
  label,
  value,
  onChange,
  type = "text",
  labelAbove,
  autocomplete,
  query,
  queryId,
  onAutocompleteSelection,
  dropdownLabelField = "name",
  ...props
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [options, setOptions] = useState([]);
  // const [autocompleteValue, setAutocompleteValue] = useState();

  const { data, isLoading } = useQuery(
    [queryId, { keyword: value }],
    () => {
      if (query) {
        return query({ keyword: value });
      }
      return;
    },
    options
  );

  const handleFocus = () => {
    // props.onFocus && props.onFocus();
    setShowSuggestions(true);
  };

  const handleOnBlur = () => {
    // props.onBlur && props.onBlur();
    setShowSuggestions(false);
  };

  const handleClick = (e) => {
    if (e.target.id !== id) {
      handleOnBlur();
    }
  };

  useEffect(() => {
    setOptions(data?.data?.["hydra:member"] || []);
  }, [data]);

  useEffect(() => {
    document.addEventListener("click", handleClick);
  }, []);

  return (
    <div className={"input-container full-width"}>
      {labelAbove && (
        <label htmlFor={id} className="label-above">
          {labelAbove}
        </label>
      )}
      <input
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        list=""
        {...props}
        onFocus={handleFocus}
        // onBlur={handleOnBlur}
      />
      {showSuggestions && !isLoading && (
        <div className="dropdown-suggestions">
          {options?.map((option) => (
            <div
              className="option"
              onClick={() => {
                onAutocompleteSelection(
                  option?.["@id"],
                  props.name,
                  option?.[dropdownLabelField]
                );
                // setAutocompleteValue(option?.[dropdownLabelField]);
                setShowSuggestions(false);
              }}
            >
              {option?.[dropdownLabelField]}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
